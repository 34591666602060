<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Create An Asset</span>
      <el-button type="text" @click="$modal.hide('asset-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="create"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="
          form.errors.first('campaign') ? form.errors.first('campaign') : null
        "
        label="Campaign"
        required
      >
        <modal-select>
          <el-select
            v-model="campaign"
            :style="{ width: '100%' }"
            clearable
            placeholder="Choose a Collection..."
            @focus="form.errors.clear('campaign')"
          >
            <el-option
              v-for="item in campaigns"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('collection')
            ? form.errors.first('collection')
            : null
        "
        label="Collection"
        required
      >
        <modal-select>
          <el-select
            v-model="asset.collection"
            :style="{ width: '100%' }"
            clearable
            placeholder="Choose a Collection..."
            @focus="form.errors.clear('collection')"
          >
            <el-option
              v-for="item in collections"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="asset.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('description')
            ? form.errors.first('description')
            : null
        "
        label="Description"
      >
        <el-input
          v-model="asset.description"
          type="textarea"
          placeholder="Description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @focus="form.errors.clear('description')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('description')
            ? form.errors.first('description')
            : null
        "
        label="Publish"
      >
        <el-checkbox v-model="asset.published">
          Immediately Publish
        </el-checkbox>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AssetCreateForm",

  mixins: [],

  props: {
    existing: {},
  },
  data() {
    return {
      campaign: "",
      asset: {
        name: "",
        description: "",
        collection: "",
        published: false,
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      collections: "collections/list",
      campaigns: "campaigns/list",
    }),
    action() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/create/"
      )
    },
  },

  methods: {
    ...mapActions({
      addAsset: "campaigns/addAsset",
    }),
    create() {
      if (this.campaign === "") {
        this.form.errors.record({
          campaign: ["The campaign field is required."],
        })

        return
      }

      this.loading = true

      this.axios
        .post(this.action, this.asset)
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.asset.collection,
            data: response.data.data,
          }
          this.addAsset(data)
          this.$modal.hide("asset-create-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    if (this.existing) {
      this.campaign = this.existing
    }
  },
}
</script>
