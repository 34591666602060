<template>
  <el-row style="margin: 1px">
    <el-col
      v-for="file in files"
      :key="file.id"
      :md="file.type === 'blank' ? 24 : 12"
      class="audio-container"
    >
      <base-media
        :collection="collection"
        :campaign="campaign"
        :asset="asset"
        :file="file"
        v-if="file.type !== 'blank'"
      >
        <audio-single :audio="file"></audio-single>
        <template slot="left">
          {{ file.name ? file.name : file.file_name }}
        </template>
        <template slot="right">
          <a :href="file.src" target="_blank">
            <font-awesome-icon icon="download" full-width></font-awesome-icon>
          </a>
        </template>
      </base-media>
    </el-col>
  </el-row>
</template>

<style>
@media only screen and (min-width: 992px) {
  .audio-container:nth-child(odd) {
    padding-right: 0.5rem;
  }

  .audio-container:nth-child(even) {
    padding-left: 0.5rem;
  }
}

.audio-container {
  padding-bottom: 0.5rem;
}
</style>

<script>
import AudioSingle from "@/partials/Media/AudioSingle"
import BaseMedia from "./Base"

export default {
  name: "MediaAudio",

  components: {
    AudioSingle,
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  computed: {
    files() {
      if (!this.data) return []
      return this.data
    },
  },
}
</script>
