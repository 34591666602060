<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Edit File</span>
      <el-button type="text" @click="$modal.hide('file-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-form
      @submit.native.prevent="update"
      label-position="right"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
      >
        <el-input
          v-model="file.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <div
        v-if="
          collection === 'facebook-timeline' ||
          collection === 'facebook-carousel'
        "
      >
        <el-form-item
          :error="
            form.errors.first('text_1') ? form.errors.first('text_1') : null
          "
          label="Text"
        >
          <el-input
            v-model="file.text_1"
            type="text"
            placeholder="Text"
            @focus="form.errors.clear('text_1')"
          ></el-input>
          <p
            class="help-text"
            v-if="collection === 'facebook-carousel' && existing.order !== 1"
          >
            This is not he first slide of the carousel. This particular value
            will only be displayed if this image is the first slide.
          </p>
        </el-form-item>

        <el-form-item
          :error="
            form.errors.first('text_2') ? form.errors.first('text_2') : null
          "
          label="Headline"
        >
          <el-input
            v-model="file.text_2"
            type="text"
            placeholder="Headline"
            @focus="form.errors.clear('text_2')"
          ></el-input>
        </el-form-item>

        <el-form-item
          :error="
            form.errors.first('text_3') ? form.errors.first('text_3') : null
          "
          label="Description"
        >
          <el-input
            v-model="file.text_3"
            type="text"
            placeholder="Description"
            @focus="form.errors.clear('text_3')"
          ></el-input>
        </el-form-item>

        <el-form-item
          :error="form.errors.first('cta') ? form.errors.first('cta') : null"
          label="CTA"
        >
          <el-input
            v-model="file.cta"
            type="text"
            placeholder="CTA"
            @focus="form.errors.clear('cta')"
          ></el-input>
        </el-form-item>
      </div>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Save
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "FileEditForm",

  mixins: [],

  props: {
    existing: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
    asset: {
      required: true,
    },
  },
  data() {
    return {
      file: {
        name: "",
        text_1: "",
        text_2: "",
        text_3: "",
        cta: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    action() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset +
        "/update/" +
        this.existing.id
      )
    },
  },

  methods: {
    ...mapActions({
      updateFile: "campaigns/updateFile",
    }),
    update() {
      this.loading = true

      let data = { name: this.file.name }

      if (
        this.collection === "facebook-timeline" ||
        this.collection === "facebook-carousel"
      ) {
        data["text_1"] = this.file.text_1
        data["text_2"] = this.file.text_2
        data["text_3"] = this.file.text_3
        data["cta"] = this.file.cta
      }

      this.axios
        .post(this.action, data)
        .then((response) => {
          let data = {
            campaign: this.campaign,
            asset: this.asset,
            collection: this.collection,
            data: response.data.data,
          }

          this.updateFile(data)
          this.$modal.hide("file-edit-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  mounted() {
    this.file = this.$_.cloneDeep(this.existing)
  },
}
</script>
