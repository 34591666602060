<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 100,
    }"
  >
    <iframe
      :src="data.src"
      :height="data.height"
      :width="data.width"
      frameborder="0"
      scrolling="no"
      ref="iframe"
      v-if="active"
    ></iframe>
  </div>
</template>

<style lang="scss">
.animated-wrapper {
  margin: 0.5rem;
  text-align: center;
  box-shadow: 0 0 0 1px #c8cdd0;
  background: #fafafa;

  iframe {
    border-bottom: 1px solid #c8cdd0;
  }

  .animated-details {
    display: flex;
    padding: 0.25rem;

    .animated-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .animated-size,
    .animated-refresh {
      flex: 0;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
</style>

<script>
export default {
  name: "AnimatedBannerSingle",

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      active: false,
    }
  },

  methods: {
    reloadIframe() {
      if (!this.active) this.active = true
      if (this.$refs.iframe) this.$refs.iframe.src = this.data.src
    },
    visibilityChanged() {
      this.active = true
      // this.active = isVisible;
    },
  },
}
</script>
