<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Send Test Email</span>
      <el-button type="text" @click="$modal.hide('email-test-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-form
      @submit.native.prevent="send"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="
          form.errors.first('subject') ? form.errors.first('subject') : null
        "
        label="Subject"
        required
      >
        <el-input
          v-model="email.subject"
          type="text"
          @focus="form.errors.clear('subject')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('from') ? form.errors.first('from') : null"
        label="From"
        required
      >
        <el-input
          v-model="email.from"
          type="text"
          @focus="form.errors.clear('from')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('to') ? form.errors.first('to') : null"
        label="Send To"
        v-if="$auth.check(['super', 'admin', 'approval-client'])"
      >
        <modal-select>
          <el-select
            v-model="email.to"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="Choose recipients"
            style="width: 100%"
            @change="onChange"
            v-if="$auth.check(['super', 'admin'])"
          >
            <el-option-group
              v-for="group in toOptions"
              :key="group.group"
              :label="group.group"
            >
              <el-option
                v-for="item in group.users"
                :key="item.email"
                :label="item.name"
                :value="item.email"
              >
              </el-option>
            </el-option-group>
            <el-option
              v-for="item in toOptions"
              :key="item.email"
              :label="item.name"
              :value="item.email"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="email.to"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="Choose recipients"
            style="width: 100%"
            @change="onChange"
            v-else
          >
            <el-option
              v-for="item in toOptions"
              :key="item.email"
              :label="item.name"
              :value="item.email"
            >
            </el-option>
          </el-select>
        </modal-select>

        <p style="font-size: 0.8rem; color: #999; margin-top: 0.5rem">
          Manual entry possible. Type a valid email and press enter to add or
          leave blank to send to yourself.
        </p>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
        v-text="loading ? 'Sending...' : 'Send'"
      ></el-button>
    </el-form>
  </el-card>
</template>

<style lang="scss">
.ts-multiselect {
  .caret {
    position: absolute;
    right: 5px;
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      color: #c0c4cc;
      font-size: 14px;
      transition: transform 0.3s;
      transform: rotateZ(180deg);
      line-height: 16px;
    }
  }

  .multiselect__tags {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    min-height: 40px;
    line-height: 40px;
    outline: none;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    padding: 0 30px 0 15px;

    .multiselect__input,
    .multiselect__single {
      font-size: 14px;
      height: 100%;
      padding: 0;
      margin: 0;
      line-height: 38px;
    }

    .multiselect__input {
      vertical-align: middle;
    }

    .multiselect__tags-wrap {
      font-size: 14px;
      padding: 0;
      margin: 0;
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      transform: translate(0, -2px);
      max-width: 100%;
      flex-wrap: wrap;

      .multiselect__tag {
        margin: 2px 2px 2px 0;
      }
    }

    .multiselect__single {
      color: #c0c4cc;
    }
  }

  .multiselect__content {
    .multiselect__option--group {
      background: #fff;
      color: #909399;
    }

    .multiselect__option--group.multiselect__option--highlight {
      background: #ddd;
      color: #606266;
    }
  }
}

.ts-multiselect.multiselect--active {
  .caret {
    i {
      transform: rotateZ(0deg);
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex"
import Form from "form-backend-validation"

export default {
  name: "EmailTestForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      email: {
        subject: "",
        to: [],
        from: "",
      },
      toOptions: [],
      form: new Form(),
      loading: false,
      temp: "",
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      company: "companies/current",
    }),
    action() {
      return "send-test/"
    },
  },
  watch: {},

  methods: {
    send() {
      this.loading = true
      this.axios
        .post(this.action + this.existing.id, this.email)
        .then((response) => {
          this.loading = false
          this.$modal.hide("email-test-form")
          this.$message.success(response.data.message)
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    onChange(values) {
      let reg =
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      let val = values[values.length - 1]
      if (!reg.test(String(val).toLowerCase())) {
        this.email.to.splice(this.email.to.indexOf(val), 1)
        this.$message.warning("Please input a valid email address.")
      }
    },
  },

  created() {},
  mounted() {
    this.email.subject = this.existing.name
      ? this.existing.name
      : this.existing.from
    this.email.from = this.existing.from
    if (["super", "admin", "approval-client"].indexOf(this.auth.role) !== -1) {
      this.email.to = []

      let clients = this.$_.map(this.company.users, (o) => {
        return { name: o.name, email: o.email }
      })
      let reps = this.$_.filter(this.company.reps, (o) => {
        return o.slug !== this.auth.slug
      })
      reps = this.$_.map(reps, (o) => {
        return { name: o.name, email: o.email }
      })

      if (["super", "admin"].indexOf(this.auth.role) !== -1) {
        this.toOptions = [
          {
            group: "Yourself",
            users: [{ name: this.auth.name, email: this.auth.email }],
          },
          {
            group: "Clients",
            users: clients,
          },
          {
            group: "Reps",
            users: reps,
          },
        ]
      } else {
        this.toOptions = this.$_.merge(
          [{ name: this.auth.name, email: this.auth.email }],
          clients
        )
      }
    } else {
      this.email.to = []
    }
  },
}
</script>
