<template>
  <vue-plyr :options="options">
    <audio controls crossorigin playsinline>
      <source :src="audio.src" :type="audio.mime_type" />
    </audio>
  </vue-plyr>
</template>

<style lang="scss">
// .audio-item {
//   max-width: 800px;
//   background-color: white;
//   box-shadow: 0 0 0 1px #c8cdd0;
//   margin: 10px auto;

//   .item-details {
//     font-size: 0.9rem;
//     padding: 5px 10px;
//     background-color: #fafafa;
//     border-top: 1px solid #c8cdd0;
//     height: 24px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }
</style>

<script>
import { EventBus } from "@/events"

export default {
  name: "AudioSingle",

  props: {
    audio: {
      required: true,
    },
  },

  data() {
    return {
      options: {
        controls: ["play", "progress", "current-time"],
        tooltips: { controls: false, seek: false },
      },
    }
  },

  methods: {
    userSeek(event) {
      let progress = event.offsetX / this.$refs.progressBar.clientWidth

      this.setProgress(progress)
    },
    userPlay() {
      EventBus.$emit("audio-played", this)
      this.play()
    },
  },

  mounted() {
    EventBus.$on("audio-played", (that) => {
      if (this._uid !== that._uid) {
        if (this.playing) {
          this.pause()
        }
      }
    })
  },
}
</script>
