<template>
  <el-row type="flex" style="flex-wrap: wrap; align-items: flex-end">
    <el-col
      style="flex: none; padding: 0.25rem"
      :sm="link.type === 'blank' ? 24 : 12"
      v-for="link in data"
      :key="link.id"
    >
      <base-media
        :file="link"
        :asset="asset"
        :collection="collection"
        :campaign="campaign"
        v-if="link.type !== 'blank'"
      >
        <div class="link">
          <div class="link-image">
            <img v-if="link.screenshot !== null" :src="link.screenshot" />
            <div class="no-image" v-else>Preview Unavailable</div>
          </div>
        </div>
        <template slot="left">
          {{ link.name }}
        </template>
        <template slot="right">
          <div class="link-icon" v-if="link.email">
            <a class="has-cursor-pointer">
              <font-awesome-icon
                :icon="emailLoading ? 'spinner' : 'share-square'"
                :spin="emailLoading"
                @click="!emailLoading ? openTestEmailModal(link) : null"
              ></font-awesome-icon>
            </a>
          </div>
          <div class="link-icon">
            <a
              v-if="link.email && link.url.substring(0, 5) === 'https'"
              class="has-cursor-pointer"
              @click="openEmailModal(link)"
            >
              <font-awesome-icon icon="eye" full-width></font-awesome-icon>
            </a>
            <a v-else :href="link.url" target="_blank">
              <font-awesome-icon
                icon="external-link-square-alt"
                full-width
              ></font-awesome-icon>
            </a>
          </div>
          <div class="link-icon" v-if="link.email">
            <a :href="link.download" target="_blank" class="has-cursor-pointer">
              <font-awesome-icon icon="download"></font-awesome-icon>
            </a>
          </div>
        </template>
      </base-media>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.link-icon {
  padding-right: 0.5rem;
}

.link {
  box-shadow: 0 0 0 1px #c8cdd0;

  .link-image {
    text-align: center;

    .no-image {
      padding: 3rem 0;
      width: 90%;
      margin: 0 auto;
      background: #ebebeb;
      color: #999;
    }

    img {
      max-width: 95%;
    }
  }

  .info {
    font-size: 0.9rem;
    padding: 5px 10px;
    background-color: #fafafa;

    .link-from {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}

.link > div:nth-child(2) {
  border-top: 1px solid #c8cdd0;
}
</style>

<script>
import UrlEmbed from "@/partials/Media/UrlEmbed"
import EmailTestForm from "@/components/Forms/EmailTestForm"
import { mapGetters } from "vuex"
import BaseMedia from "./Base"

export default {
  name: "MediaUrl",

  components: {
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      emailLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
    action() {
      return "send-test/"
    },
  },

  methods: {
    openEmailModal(link) {
      this.$modal.show(
        UrlEmbed,
        {
          email: link,
        },
        {
          width: "95%",
          maxWidth: 700,
          name: "url-embed",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openTestEmailModal(link) {
      if (!this.emailLoading) {
        this.$modal.show(
          EmailTestForm,
          {
            existing: link,
          },
          {
            name: "email-test-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      }
    },
    sendTest(link) {
      if (
        ["super", "admin", "approval-client"].indexOf(this.auth.role) !== -1
      ) {
        this.$prompt("Please input an Email", "Send Test Email", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          inputPattern:
            /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: "Invalid Email",
          inputValue: this.auth.email,
        })
          .then((value) => {
            this.confirmedSendTest(link, value.value)
          })
          .catch(() => {})
      } else {
        this.confirmedSendTest(link, null)
      }
    },
    confirmedSendTest(item, email) {
      this.emailLoading = true
      this.axios
        .post(this.action + item.id, { to: email })
        .then((response) => {
          this.emailLoading = false
          this.$message.success(response.data.message)
        })
        .catch((resp) => {
          this.emailLoading = false
          this.$root.globalRequestError(resp)
        })
    },
  },
}
</script>
