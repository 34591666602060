<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Attach a link to Asset {{ asset.name }}</span>
      <el-button type="text" @click="$modal.hide('link-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="create"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('url') ? form.errors.first('url') : null"
        label="URL"
        required
      >
        <el-input
          v-model="link.url"
          type="text"
          placeholder="URL"
          @focus="form.errors.clear('url')"
          v-input-focus
        >
          <template slot="append" v-if="link.type === 'email' && !secure">
            <el-tooltip
              class="item"
              effect="dark"
              content="Emails that are insecure cannot be embedded. Please use https."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="warning"
                icon="exclamation"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        :label="link.type === 'email' ? 'Subject' : 'Name'"
      >
        <el-input
          v-model="link.name"
          type="text"
          :placeholder="link.type === 'email' ? 'Subject' : 'Name'"
          @focus="form.errors.clear('name')"
        >
          <template slot="append">
            <el-tooltip
              class="item"
              effect="dark"
              content="Optional. Title of the website will be used if left blank."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="info"
                icon="info-circle"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="link.type === 'email'"
        :error="form.errors.first('from') ? form.errors.first('from') : null"
        label="From"
      >
        <el-input
          v-model="link.from"
          type="text"
          placeholder="From"
          @focus="form.errors.clear('from')"
        >
          <template slot="append">
            <el-tooltip
              class="item"
              effect="dark"
              content="Optional. Current name of company will be used if left blank."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="info"
                icon="info-circle"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "LinkCreateForm",

  mixins: [],

  props: {
    asset: {
      required: true,
    },
    collectionType: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      link: {
        url: "",
        type: "links",
        name: "",
        from: "",
      },
      form: new Form(),
      loading: false,
      secure: true,
    }
  },
  computed: {
    action() {
      return (
        "urls/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset.slug +
        "/create/"
      )
    },
  },
  watch: {
    "link.url": {
      handler: function (newVal) {
        let val = newVal.trim()
        if (val.length > 4) {
          if (val.substring(0, 5) !== "https") {
            this.secure = false
          } else {
            this.secure = true
          }
        } else {
          this.secure = true
        }
      },
    },
  },

  methods: {
    ...mapActions({
      addFile: "campaigns/addFile",
    }),
    create() {
      this.loading = true

      this.axios
        .post(this.action, this.link)
        .then((response) => {
          let payload = {
            campaign: this.campaign,
            collection: this.collection,
            asset: this.asset.slug,
            data: response.data.data,
          }
          this.addFile(payload)
          this.$modal.hide("link-create-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  mounted() {
    if (this.collectionType.slug === "emails") {
      this.link.type = "email"
    }
  },
}
</script>
