<template>
  <div style="display: inline-flex; align-items: flex-end">
    <a
      @click="openAssetCommentsModal"
      class="comments-link has-cursor-pointer"
      v-if="asset.comments.length > 0"
    >
      <font-awesome-icon
        icon="comments"
        :class="{ grey: true, info: asset.comments.length > 0 }"
      ></font-awesome-icon>
      <span
        class="comment-count"
        :class="{ info: asset.comments.length > 0 }"
        >{{ asset.comments.length }}</span
      >
    </a>
    <modal
      v-bind="options"
      :name="'asset-comments-' + campaign + '-' + asset.slug"
    >
      <asset-comment-modal
        :asset="asset"
        :campaign="campaign"
        :collection="collection"
      ></asset-comment-modal>
    </modal>
  </div>
</template>

<style lang="scss">
.comments-link {
  color: #ddd !important;
  padding-left: 0.25rem;
  display: inline-flex;
  align-items: center;

  .comment-count {
    font-size: 0.75rem;
    margin-left: 0.25rem;
  }

  .comment-count.info {
    color: #409eff;
  }
}
</style>


<script>
import AssetCommentModal from "@/partials/Comments/Asset"

import DateTime from "@/mixins/DateTime"
import { mapActions } from "vuex"

export default {
  name: "AssetApprovalForm",
  mixins: [DateTime],
  components: {
    AssetCommentModal,
  },

  props: {
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      approvalLoading: false,
      options: {
        adaptive: true,
        height: "auto",
        scrollable: true,
        width: 800,
      },
    }
  },
  computed: {
    approvalAction() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/approve/" +
        this.asset.slug
      )
    },
    tooltipMessage() {
      if (this.asset.approved) {
        return (
          "Asset approved by " +
          this.asset.approved_by.name +
          " on " +
          this.dateTimeFormat(this.asset.approved_at) +
          "."
        )
      } else if (
        !this.asset.approved &&
        this.$auth.check(["super", "approval-client"])
      ) {
        return "Pending approval. Click to approve."
      } else {
        return "Pending approval."
      }
    },
  },

  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
    }),
    approveAsset() {
      let message = "Are you sure?"
      if (!this.$auth.check("super")) {
        message += " <strong>This action cannot be undone</strong>."
      } else {
        message += " Assets can be unapproved by editing them."
      }
      this.$confirm(message, "Approve " + this.asset.name + "?", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "info",
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          if (action === "confirm") {
            this.approvalLoading = true
            this.axios
              .post(this.approvalAction, {})
              .then((response) => {
                let data = {
                  campaign: this.campaign,
                  collection: this.collection,
                  data: response.data.data,
                }

                this.updateAsset(data)
                this.$message.success(response.data.message)
                this.approvalLoading = false
              })
              .catch((resp) => {
                this.$root.globalRequestError(resp)
                this.approvalLoading = false
              })
          }
        },
      }).catch(() => {})
    },
    openAssetCommentsModal() {
      this.$modal.show(
        "asset-comments-" + this.campaign + "-" + this.asset.slug
      )
    },
  },
}
</script>
