<template>
  <div class="asset-view-container">
    <el-row class="asset-header">
      <el-col style="flex: 1">
        <span class="asset-controls">
          <span class="asset-name" :class="{ unpublished: !asset.published }">
            {{ asset.name }}
            <font-awesome-icon
              icon="eye-slash"
              v-if="!asset.published"
              style="padding-left: 0.5rem"
            ></font-awesome-icon>
          </span>

          <asset-approval-form
            :asset="asset"
            :campaign="campaign"
            :collection="collection"
            style="display: inline-block"
          ></asset-approval-form>

          <a
            v-if="collectionType && collectionType.downloadable && downloadable"
            class="has-cursor-pointer"
            @click="download"
          >
            <font-awesome-icon icon="download" fixed-width></font-awesome-icon>
          </a>

          <a
            v-if="asset.description && asset.description !== ''"
            class="has-cursor-pointer"
            @click="descActive = !descActive"
          >
            <font-awesome-icon
              icon="info-circle"
              fixed-width
            ></font-awesome-icon>
          </a>
        </span>
      </el-col>
    </el-row>

    <slide-up-down
      :active="descActive"
      :duration="500"
      v-if="asset.description && asset.description !== ''"
    >
      <div class="asset-description">
        <div
          class="asset-description-container"
          v-html="asset.description"
        ></div>
      </div>
    </slide-up-down>

    <div
      style="margin-top: 0.5rem; overflow-y: hidden"
      :style="{
        'overflow-x':
          ['facebook-carousel', 'facebook-timeline'].indexOf(collection) !== -1
            ? 'auto'
            : 'hidden',
      }"
    >
      <div v-if="images.length && mixed" class="mixed-media-header">
        <span>
          <font-awesome-icon icon="images" full-width></font-awesome-icon> Image
        </span>
      </div>

      <div
        v-if="
          images.length &&
          collection !== 'facebook-carousel' &&
          collection !== 'facebook-timeline' &&
          collection !== 'banner-ads'
        "
      >
        <media-image
          :data="images"
          :asset="asset"
          :collection="collection"
          :campaign="campaign"
        ></media-image>
      </div>

      <media-image
        v-if="images.length && collection === 'banner-ads'"
        :data="images"
        :asset="asset"
        :collection="collection"
        :campaign="campaign"
      ></media-image>

      <media-carousel
        v-if="images.length && collection === 'facebook-carousel'"
        :data="images"
        :asset="asset"
        :collection="collection"
        :campaign="campaign"
      ></media-carousel>

      <media-timeline
        v-if="images.length && collection === 'facebook-timeline'"
        :data="images"
        :asset="asset"
        :collection="collection"
        :campaign="campaign"
      ></media-timeline>

      <media-animated-banner
        v-if="animated_banners.length"
        :data="animated_banners"
        :asset="asset"
        :collection="collection"
        :campaign="campaign"
      ></media-animated-banner>

      <div v-if="videos.length">
        <div v-if="mixed" class="mixed-media-header">
          <span>
            <font-awesome-icon icon="video" full-width></font-awesome-icon>
            Videos
          </span>
        </div>
        <media-video
          :data="videos"
          :asset="asset"
          :collection="collection"
          :campaign="campaign"
        ></media-video>
      </div>

      <div v-if="audio.length">
        <div v-if="mixed" class="mixed-media-header">
          <span>
            <font-awesome-icon icon="headphones" full-width></font-awesome-icon>
            Audio
          </span>
        </div>
        <media-audio
          :data="audio"
          :asset="asset"
          :collection="collection"
          :campaign="campaign"
        ></media-audio>
      </div>

      <div v-if="urls.length">
        <div v-if="mixed" class="mixed-media-header">
          <span>
            <font-awesome-icon icon="globe" full-width></font-awesome-icon>
            Links
          </span>
        </div>
        <media-url
          :data="urls"
          :asset="asset"
          :collection="collection"
          :campaign="campaign"
        ></media-url>
      </div>

      <div v-if="files.length">
        <div v-if="mixed" class="mixed-media-header">
          <span>
            <font-awesome-icon icon="box" full-width></font-awesome-icon> Files
          </span>
        </div>
        <media-file
          :data="files"
          :asset="asset"
          :collection="collection"
          :campaign="campaign"
        ></media-file>
      </div>

      <div v-if="this.asset.files.length === 0">
        <p>There are no files in this asset.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>

<script>
import MediaImage from "@/partials/Media/Image"
import MediaCarousel from "@/partials/Media/Carousel"
import MediaVideo from "@/partials/Media/Video"
import MediaAudio from "@/partials/Media/Audio"
import MediaUrl from "@/partials/Media/Url"
import MediaFile from "@/partials/Media/File"
import MediaAnimatedBanner from "@/partials/Media/AnimatedBanner"
import MediaTimeline from "@/partials/Media/Timeline"
import AssetApprovalForm from "@/components/Forms/AssetApprovalForm"
import SlideUpDown from "vue-slide-up-down"

export default {
  name: "AssetView",

  mixins: [],
  components: {
    MediaImage,
    MediaCarousel,
    MediaVideo,
    MediaAudio,
    MediaUrl,
    MediaFile,
    MediaAnimatedBanner,
    MediaTimeline,
    AssetApprovalForm,
    SlideUpDown,
  },

  props: {
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collectionType: {
      required: true,
    },
  },
  data() {
    return {
      descActive: false,
    }
  },
  computed: {
    images() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "image" && !valid) valid = true
        return obj.type === "image" || obj.type === "blank"
      })
      return valid ? data : []
    },
    videos() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "video" && !valid) valid = true
        return obj.type === "video" || obj.type === "blank"
      })
      return valid ? data : []
    },
    audio() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "audio" && !valid) valid = true
        return obj.type === "audio" || obj.type === "blank"
      })
      return valid ? data : []
    },
    urls() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "url" && !valid) valid = true
        return obj.type === "url" || obj.type === "blank"
      })
      return valid ? data : []
    },
    files() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "generic" && !valid) valid = true
        return obj.type === "generic" || obj.type === "blank"
      })
      return valid ? data : []
    },
    animated_banners() {
      let valid = false
      let data = this.asset.files.filter(function (obj) {
        if (obj.type === "animated-banner" && !valid) valid = true
        return obj.type === "animated-banner" || obj.type === "blank"
      })
      return valid ? data : []
    },
    mixed() {
      let available = 0
      if (this.images.length) available++
      if (this.videos.length) available++
      if (this.audio.length) available++
      if (this.urls.length) available++
      if (this.files.length) available++
      if (this.animated_banners.length) available++

      return available > 1
    },
    downloadable() {
      let available = 0
      if (this.images.length) available++
      if (this.videos.length) available++
      if (this.audio.length) available++
      if (this.files.length) available++

      return available > 0
    },
    downloadAction() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/download/" +
        this.asset.slug
      )
    },
  },

  methods: {
    download() {
      this.axios
        .get(this.downloadAction)
        .then((response) => {
          let link = window.open(response.data.data, "_blank")
          if (!link || link.closed || typeof link.closed === "undefined") {
            this.$message.warning("Please allow pop ups to download this file.")
          } else {
            this.$message.success("Your download should start shortly.")
          }
          this.$modal.hide("email-test-form")
        })
        .catch((resp) => {
          this.$root.globalRequestError(resp)
        })
    },
  },
}
</script>
