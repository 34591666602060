<template>
  <div class="video-container">
    <div class="video-item" v-if="embed && success" v-html="embed"></div>
    <div v-else>
      <div class="video-processing">
        <img :src="video.thumbnail" style="width: 100%" />
        <div class="overlay"><p>Processing...</p></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.vjs-custom-skin {
  max-width: 800px;
  height: auto;
}

.video-container {
  width: 100%;
}

.video-item {
  max-width: 800px;
  background-color: white;
  box-shadow: 0 0 0 1px #c8cdd0;
  margin: 10px auto;

  .item-details {
    font-size: 0.9rem;
    padding: 5px 10px;
    background-color: #fafafa;
    border-top: 1px solid #c8cdd0;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video-details {
  padding: 5px 10px;
  max-height: 40px;
  background-color: #fafafa;
  border-top: 1px solid #c8cdd0;
}

.video-processing {
  position: relative;
  width: 100%;
}

.video-processing .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.video-processing .overlay p:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  line-height: 3rem;
  transform: translate(-50%, -50%);
}

.video-processing {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}
</style>

<script>
import axios from "axios"

export default {
  name: "MediaVideoSingle",

  props: {
    video: {
      required: true,
    },
  },
  data() {
    return {
      embed: null,
      success: false,
    }
  },

  methods: {
    encodeGetParams(p) {
      return Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&")
    },
  },

  created() {},
  mounted() {
    let client = axios.create()
    let params = {
      url: this.video.vimeo_url,
      autopause: true,
      byline: false,
      color: "E34D42",
      portrait: false,
      responsive: true,
      title: false,
    }

    client
      .get("https://vimeo.com/api/oembed.json?" + this.encodeGetParams(params))
      .then(({ data }) => {
        this.embed = data.html
        this.success = true
      })
      .catch(() => (this.success = false))
  },
}
</script>
