<template>
  <el-card
    class="collection-container"
    v-if="hasFiles || $auth.check(['super', 'admin', 'employee'])"
  >
    <el-row class="collection-header">
      <el-col style="flex: 1">
        <span
          class="collection-name"
          v-text="collectionType ? collectionType.name : ''"
        ></span>
      </el-col>
      <el-col
        style="flex: 0; white-space: nowrap"
        class="collection-controls"
        v-show="editing"
      >
        <el-button
          size="small"
          v-if="editing && assets.length > 1"
          type="info"
          @click="openReorderAssetsModal"
        >
          Reorder
        </el-button>
      </el-col>
    </el-row>
    <transition-group name="fade" mode="out-in">
      <template v-if="editing && $auth.check(['admin', 'super'])">
        <asset-admin-view
          v-for="asset in assets"
          :key="asset.slug"
          :asset="asset"
          :campaign="campaign"
          :collection="collection.type"
        ></asset-admin-view>
        <div style="padding: 0 10px 10px" v-if="assets.length === 0">
          <p style="margin: 0">There are no assets for this collection.</p>
        </div>
      </template>
      <template v-else>
        <div v-for="asset in assets" :key="asset.slug">
          <asset-view
            :asset="asset"
            :collection="collection.type"
            :collection-type="collectionType"
            :campaign="campaign"
            v-if="
              asset.published || $auth.check(['super', 'admin', 'employee'])
            "
          ></asset-view>
        </div>
        <div style="padding: 0 10px 10px" v-if="assets.length === 0">
          <p style="margin: 0">There are no assets for this collection.</p>
        </div>
      </template>
    </transition-group>
  </el-card>
</template>

<style lang="scss">
</style>

<script>
import AssetView from "@/partials/Month/Asset"
import AssetAdminView from "@/partials/Month/AssetAdmin"
import AssetSortForm from "@/components/Forms/AssetSortForm"
import { mapGetters } from "vuex"

export default {
  name: "CollectionView",

  components: {
    AssetView,
    AssetAdminView,
  },

  props: {
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
    editing: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      collectionTypes: "collections/list",
    }),
    collectionType() {
      let index = this.$_.findIndex(this.collectionTypes, {
        slug: this.collection.type,
      })
      return this.collectionTypes[index]
    },
    assets() {
      if (this.$auth.check(["super", "admin", "employee"])) {
        return this.collection.assets
      } else {
        return this.$_.filter(this.collection.assets, function (o) {
          return o.published
        })
      }
    },
    hasFiles() {
      if (this.$auth.check(["admin", "super", "employee"])) return true
      if (!this.assets.length) return false
      if (this.$_.some(this.assets, "file_count")) {
        return true
      }
      return false
    },
  },

  methods: {
    openReorderAssetsModal() {
      this.$modal.show(
        AssetSortForm,
        {
          data: this.collection.assets,
          campaign: this.campaign,
        },
        {
          name: "asset-sort-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
  },
}
</script>
