<template>
  <!-- eslint-disable -->
  <div class="comment-list">
    <el-row v-if="data.length === 0" class="comment-item">
      <div class="content">
        <div class="heading">
          <strong>No comments</strong>
        </div>
        <!--<div class="body">-->
        <!--Be the first to comment using the form below.-->
        <!--</div>-->
      </div>
    </el-row>
    <template v-else>
      <transition-group name="fade">
        <el-row
          v-for="(comment, key) in data"
          :key="commend.id"
          type="flex"
          class="comment-item"
          :class="{ 'approval-comment': comment.id === -1 }"
        >
          <div class="avatar">
            <img
              v-if="comment.by.avatar"
              :src="comment.by.avatar"
              class="image"
            />
            <font-awesome-icon
              v-else
              icon="user"
              class="icon"
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="content">
            <div class="heading">
              <span class="comment-name">
                {{ comment.by.name }}
              </span>
              <span class="comment-date">
                {{ dateTimeFormat(comment.created) }}
              </span>
              <font-awesome-icon
                v-if="
                  comment.id !== -1 &&
                  ($auth.check('super') || comment.by.slug === auth.slug)
                "
                icon="trash"
                style="float: right; cursor: pointer"
                @click="remove(comment)"
              ></font-awesome-icon>
              <font-awesome-icon
                v-else-if="comment.id === -1"
                icon="check"
                fixed-width
                style="float: right"
              ></font-awesome-icon>
            </div>
            <div class="body" v-text="comment.body"></div>
          </div>
        </el-row>
      </transition-group>
    </template>
  </div>
</template>

<style lang="scss">
.comment-list {
  .comment-item {
    padding: 1rem;

    .avatar {
      flex: 0;
      min-width: 30px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px 0 rgba(0, 0, 0, 0.08);
      margin-right: 0.5rem;

      .icon,
      .image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
    .content {
      flex: 1;
      width: 100%;

      .heading {
        .comment-name {
          font-size: 0.9rem;
          font-weight: 700;
        }

        .comment-date {
          font-size: 0.9rem;
        }

        margin-bottom: 0.25rem;
      }

      .body {
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 1rem;
        line-height: 1.1rem;
      }
    }
  }

  .comment-item:nth-child(2n) {
    background-color: #fafafa;
  }

  .comment-item.approval-comment {
    background-color: #f0f9eb;
    color: #2e5c2e !important;

    .avatar.icon {
      color: #303133;
    }
  }

  margin-bottom: 0.5rem;
}
</style>

<script>
import DateTime from "@/mixins/DateTime"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CommentList",

  mixins: [DateTime],

  props: {
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
    }),
    action() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/comment/" +
        this.asset.slug +
        "/delete/"
      )
    },
    data() {
      if (!this.asset.approved) return this.$_.cloneDeep(this.asset.comments)
      let comment = {
          body: "Asset approved.",
          by: this.asset.approved_by,
          created: this.asset.approved_at,
          id: -1,
        },
        temp = this.$_.cloneDeep(this.asset.comments)

      if (!temp.length || temp[0].created >= this.asset.approved_at) {
        temp.splice(0, 0, comment)
      } else if (temp[temp.length - 1].created <= this.asset.approved_at) {
        temp.splice(temp.length - 1, 0, comment)
      } else {
        this.$_.forEach(this.asset.comments, (val, key) => {
          if (val.created >= this.asset.approved_at) {
            temp.splice(key, 0, comment)
            return false
          }
        })
      }

      return temp
    },
  },

  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
    }),
    remove(item) {
      this.$confirm("Delete comment? This action cannot be undone.", "", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "error",
        callback: (action) => {
          if (action === "confirm") {
            this.axios
              .post(this.action + item.id, {})
              .then((response) => {
                let data = {
                  campaign: this.campaign,
                  collection: this.collection,
                  data: response.data.data,
                }
                this.updateAsset(data)
                this.$message.success(response.data.message)
              })
              .catch((resp) => {
                this.$root.globalRequestError(resp)
              })
          }
        },
      }).catch(() => {})
    },
  },
}
</script>
