<template>
  <div class="images-container">
    <div
      v-for="image in images"
      :key="image.id"
      :class="{
        'image-new-line': image.type === 'blank',
        'image-container': image.type !== 'blank',
      }"
      :data-src="image.src"
    >
      <base-media
        :collection="collection"
        :campaign="campaign"
        :asset="asset"
        :file="image"
        v-if="image.type !== 'blank'"
        :style="{ width: image.width > 500 ? '500px' : image.width + 'px' }"
      >
        <a :href="image.src" :class="'lightbox-' + _uid">
          <img
            :src="image.thumbnail"
            :style="{ width: image.width > 500 ? '500px' : image.width + 'px' }"
          />
        </a>
        <template slot="left">
          {{ image.name ? image.name : image.file_name }}
        </template>
        <template slot="right">
          {{ image.width }} x {{ image.height }}
        </template>
      </base-media>
    </div>
  </div>
</template>

<style lang="scss">
.images-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .image-new-line {
    width: 100%;
  }

  .image-wrapper {
    /*margin: .5rem;*/
    text-align: center;
    /*box-shadow: 0 0 0 1px #c8cdd0;*/
    cursor: pointer;
    overflow: hidden;
    /*background: #fafafa;*/

    img {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #c8cdd0;
    }

    .image-details {
      display: flex;
      padding: 0.25rem;

      .image-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }

      .image-size {
        flex: 0;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
  }
}
</style>

<script>
import BaseMedia from "./Base.vue"
import { LuminousGallery, Luminous } from "luminous-lightbox"

export default {
  name: "MediaImage",

  components: {
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      hidden: false,
      lightbox: null,
    }
  },
  computed: {
    images() {
      return this.data
    },
  },

  beforeDestroy() {
    this.hidden = true
    this.lightbox.destroy()
  },

  mounted() {
    if (this.images.length > 1) {
      this.lightbox = new LuminousGallery(
        document.querySelectorAll(".lightbox-" + this._uid)
      )
    } else {
      this.lightbox = new Luminous(
        document.querySelectorAll(".lightbox-" + this._uid)[0]
      )
    }
  },
}
</script>
