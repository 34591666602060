<template>
  <div>
    <el-form @submit.native.prevent="create" class="comment-form">
      <el-form-item
        :error="form.errors.first('body') ? form.errors.first('body') : null"
        :label="null"
      >
        <el-input
          v-model="body"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @focus="form.errors.clear('body')"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Comment
      </el-button>
    </el-form>
  </div>
</template>

<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "FileForm",
  props: {
    file: {
      required: true,
    },
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      body: "",
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    action() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset.slug +
        "/comment/" +
        this.file.id
      )
    },
  },
  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
    }),
    create() {
      this.loading = true

      this.axios
        .post(this.action, { body: this.body })
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.collection,
            data: response.data.data,
          }
          this.updateAsset(data)
          this.$message.success(response.data.message)
          this.body = ""
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
.comment-form {
  padding: 0.5rem;
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
</style>
