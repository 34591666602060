<template>
  <el-row style="margin: 1px">
    <el-col
      v-for="file in data"
      :key="file.id"
      :md="file.type === 'blank' ? 24 : 12"
      class="multi-video-container"
    >
      <base-media
        :collection="collection"
        :campaign="campaign"
        :asset="asset"
        :file="file"
        v-if="file.type !== 'blank'"
      >
        <video-single :video="file"></video-single>

        <template slot="left">
          {{ file.name ? file.name : file.file_name }}
        </template>
        <template slot="right">
          <span style="padding-right: 0.25rem">
            {{ file.duration | formatLength }}
          </span>
        </template>
      </base-media>
    </el-col>
  </el-row>
</template>

<style lang="scss">
@media only screen and (min-width: 992px) {
  .multi-video-container:nth-child(odd) {
    padding-right: 0.25rem;
  }
  .multi-video-container:nth-child(even) {
    padding-left: 0.25rem;
  }
}

.multi-video-container {
  padding-bottom: 0.25rem;
}
</style>

<script>
import VideoSingle from "@/partials/Media/VideoSingle"
import BaseMedia from "./Base"

export default {
  name: "MediaVideo",

  components: {
    VideoSingle,
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
}
</script>
