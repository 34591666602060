<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Create A Campaign</span>
      <el-button type="text" @click="$modal.hide('campaign-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-form
      @submit.native.prevent="create"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="campaign.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('dates') ? form.errors.first('dates') : null"
        label="Flight Dates"
        required
      >
        <el-date-picker
          v-model="campaign.dates"
          type="daterange"
          align="center"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :default-value="pickerDefault"
          format="M/d/yyyy"
          value-format="yyyy-MM-dd"
          style="width: 100%"
          @focus="form.errors.clear('dates')"
          :picker-options="{ firstDayOfWeek: 7 }"
        ></el-date-picker>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('description')
            ? form.errors.first('description')
            : null
        "
        label="Description"
      >
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Description"
          v-model="campaign.description"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "CampaignCreateForm",

  mixins: [],

  props: {
    month: {
      required: true,
    },
  },
  data() {
    return {
      campaign: {
        name: "",
        dates: "",
        description: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    pickerDefault() {
      let date
      if (this.$route.params.month !== "evergreen") {
        date = this.$moment(this.$route.params.month, "MM-YYYY").add(
          1,
          "months"
        )
      } else {
        date = this.$moment()
      }
      return date
    },
  },

  methods: {
    ...mapActions({
      addCampaign: "campaigns/refresh",
    }),
    create() {
      this.loading = true

      let action =
        "campaigns/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/create/"
      this.axios
        .post(action, this.campaign)
        .then((response) => {
          this.addCampaign(response.data.data)
          this.$modal.hide("campaign-create-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>
