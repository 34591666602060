<template>
  <div style="position: relative">
    <div v-if="collectionType.mime_types !== null">
      <el-upload
        class="uploader"
        :action="url"
        :on-success="addFileToAsset"
        multiple
        :file-list="files"
        :headers="uploadConfig.headers"
        :data="uploadConfig.params"
        :http-request="uploadFile"
        :before-upload="accept"
        drag
      >
        <p>Drag Files or Click here to upload to {{ asset.name }}</p>
        <small style="margin-top: 5px"
          >Max file size of 500MB.{{
            collectionType.mime_types !== null
              ? " " + collectionType.mime_types.join(", ") + " files accepted"
              : ""
          }}</small
        >
      </el-upload>
      <div class="asset-admin-container">
        <div class="asset-admin-header">
          <div class="asset-admin-header-name-container">
            <span
              class="asset-admin-header-name"
              :class="{ unpublished: !asset.published }"
            >
              {{ asset.name }}
              <font-awesome-icon
                v-if="!asset.published"
                icon="eye-slash"
                fixed-width
                style="padding-left: 0.5rem"
              ></font-awesome-icon>
            </span>
          </div>

          <div class="asset-admin-header-controls">
            <el-button
              size="small"
              type="warning"
              v-if="
                table && collectionType.orderable && !dragging && totalFiles > 1
              "
              @click="insertLineBreak"
            >
              <font-awesome-icon
                icon="plus"
                fixed-width
                style="margin-right: 0.25rem"
              ></font-awesome-icon>
              Line Break
            </el-button>
            <el-button
              size="small"
              type="info"
              @click="initReOrder"
              v-if="
                table && collectionType.orderable && !dragging && totalFiles > 1
              "
            >
              Reorder
            </el-button>
            <el-button
              size="small"
              @click="cancelReOrder"
              v-if="
                table && collectionType.orderable && dragging && totalFiles > 1
              "
              v-loading="reorderLoading"
            >
              Cancel Reorder
            </el-button>
            <el-button
              size="small"
              type="info"
              @click="saveReOrder"
              v-if="
                table && collectionType.orderable && dragging && totalFiles > 1
              "
              v-loading="reorderLoading"
            >
              Save Reorder
            </el-button>
            <el-button size="small" type="success" @click="editAsset">
              Edit
            </el-button>
            <el-dropdown trigger="click" @command="assetDropDown">
              <span class="el-dropdown-link">
                <el-button
                  type="text"
                  size="small"
                  style="margin-right: 0.5rem; margin-left: 0.5rem"
                >
                  <font-awesome-icon
                    icon="ellipsis-v"
                    fixed-width
                  ></font-awesome-icon>
                </el-button>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="publish">
                  <font-awesome-icon
                    :icon="asset.published ? 'eye-slash' : 'eye'"
                    fixed-width
                  ></font-awesome-icon>
                  {{ asset.published ? "Unpublish" : "Publish" }}
                </el-dropdown-item>
                <el-dropdown-item
                  command="unapprove"
                  v-if="asset.approved && $auth.check('super')"
                  v-loading="approvalLoading"
                >
                  <font-awesome-icon
                    icon="times"
                    fixed-width
                  ></font-awesome-icon>
                  Unapprove
                </el-dropdown-item>
                <el-dropdown-item command="delete">
                  <font-awesome-icon
                    icon="trash"
                    fixed-width
                  ></font-awesome-icon>
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="asset-admin-upload-container">
          <div class="asset-admin-upload-controls">
            <el-button
              class="attach-links"
              size="medium"
              type="text"
              v-if="collectionType.linkable"
              style="padding: 3px 0.25rem; float: right"
              @click="openLinkModal"
            >
              Click Here to Attach a Link
            </el-button>
          </div>
        </div>

        <file-table
          :data="asset.files"
          :campaign="campaign"
          :collection="collection"
          :collectionType="collectionType"
          :asset="asset.slug"
          ref="fileTable"
        ></file-table>
      </div>
    </div>
    <div v-else class="asset-admin-container">
      <div class="asset-admin-header">
        <div class="asset-admin-header-name-container">
          <span
            class="asset-admin-header-name"
            :class="{ unpublished: !asset.published }"
          >
            {{ asset.name }}
            <font-awesome-icon
              v-if="!asset.published"
              icon="eye-slash"
              fixed-width
              style="padding-left: 0.5rem"
            ></font-awesome-icon>
          </span>
        </div>
        <div class="asset-admin-header-controls">
          <el-button
            size="small"
            type="info"
            @click="initReOrder"
            v-if="
              table && collectionType.orderable && !dragging && totalFiles > 1
            "
          >
            Reorder
          </el-button>
          <el-button
            size="small"
            @click="cancelReOrder"
            v-if="
              table && collectionType.orderable && dragging && totalFiles > 1
            "
            v-loading="reorderLoading"
          >
            Cancel Reorder
          </el-button>
          <el-button
            size="small"
            type="info"
            @click="saveReOrder"
            v-if="
              table && collectionType.orderable && dragging && totalFiles > 1
            "
            v-loading="reorderLoading"
          >
            Save Reorder
          </el-button>
          <el-button size="small" type="success" @click="editAsset">
            Edit
          </el-button>
          <el-dropdown trigger="click" @command="assetDropDown">
            <span class="el-dropdown-link">
              <el-button
                type="text"
                size="small"
                style="margin-right: 0.5rem; margin-left: 0.5rem"
              >
                <font-awesome-icon
                  icon="ellipsis-v"
                  fixed-width
                ></font-awesome-icon>
              </el-button>
            </span>

            <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
              <el-dropdown-item command="publish">
                <font-awesome-icon
                  :icon="asset.published ? 'eye-slash' : 'eye'"
                  fixed-width
                ></font-awesome-icon>
                {{ asset.published ? "Unpublish" : "Publish" }}
              </el-dropdown-item>

              <el-dropdown-item
                command="unapprove"
                v-if="asset.approved && $auth.check('super')"
                v-loading="approvalLoading"
              >
                <font-awesome-icon icon="times" fixed-width></font-awesome-icon>
                Unapprove
              </el-dropdown-item>

              <el-dropdown-item command="delete">
                <font-awesome-icon icon="trash" fixed-width></font-awesome-icon>
                Delete
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="asset-admin-upload-container">
        <div class="asset-admin-upload-table"></div>
        <div class="asset-admin-upload-controls">
          <el-button
            class="attach-links"
            size="medium"
            type="text"
            v-if="collectionType.linkable"
            style="padding: 3px 0.25rem; float: right"
            @click="openLinkModal"
          >
            Click Here to Attach a Link
          </el-button>
        </div>
      </div>

      <file-table
        :data="asset.files"
        :campaign="campaign"
        :collection="collection"
        :collectionType="collectionType"
        :asset="asset.slug"
        ref="fileTable"
      ></file-table>
    </div>
  </div>
</template>

<style lang="scss">
</style>

<style lang="scss">
.file-upload {
  .overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(227, 77, 66, 0.5);
    color: #222;
    z-index: 2;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
}

.file-upload.v-transmit__upload-area--is-dragging {
  .overlay {
    display: block;
  }
}

.attach-links {
  color: #409eff;
}

.attach-links:hover {
  color: #3581cc;
}

.uploader {
  margin: 10px;
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 20px;
      > * {
        width: 100%;
      }
    }
  }
}
</style>

<script>
import FileTable from "@/components/Tables/FileTable"
import LinkCreateForm from "@/components/Forms/LinkCreateForm"
import AssetEditForm from "@/components/Forms/AssetEditForm"
import Vue from "vue"
import { findIndex } from "lodash"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AssetAdmin",

  components: { FileTable },

  props: {
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      files: [],
      isMounted: false,
      approvalLoading: false
    }
  },
  computed: {
    ...mapGetters({
      collectionTypes: "collections/list",
    }),
    uploadConfig() {
      return {
        headers: {
          authorization: "Bearer " + this.$auth.token(),
          accept: "application/json",
        },
        params: {
          collection: this.collectionType.slug,
        },
      }
    },
    url() {
      return (
        Vue.axios.defaults.baseURL +
        "/files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset.slug +
        "/create"
      )
    },
    deleteAction() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/delete/" +
        this.asset.slug
      )
    },
    publishAction() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/publish/" +
        this.asset.slug
      )
    },
    approvalAction() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/unapprove/" +
        this.asset.slug
      )
    },
    collectionType() {
      let index = this.$_.findIndex(this.collectionTypes, {
        slug: this.collection,
      })
      return this.collectionTypes[index]
    },
    table() {
      if (!this.isMounted) return
      return this.$refs.fileTable
    },
    dragging() {
      return this.table.dragging
    },
    totalFiles() {
      return this.table.files.length
    },
    reorderLoading() {
      return this.table.reorderLoading
    },
  },

  methods: {
    ...mapActions({
      addFile: "campaigns/addFile",
      removeAsset: "campaigns/removeAsset",
      updateAsset: "campaigns/updateAsset",
    }),
    assetDropDown(command) {
      if (command === "publish") {
        this.publishAsset()
      } else if (command === "unapprove") {
        this.unapproveAsset()
      } else if (command === "delete") {
        this.deleteAsset()
      }
    },
    accept(file) {
      return new Promise((resolve, reject) => {
        if (file.size / 1024 / 1024 > 500) {
          this.$message.warning("Maximum file size of 500 MB.")
          reject()
        }
        if (this.collectionType.mime_types.length) {
          if (this.hasExtension(file.name, this.collectionType.mime_types)) {
            resolve()
          } else {
            this.$message.warning(
              "File provided is of an invalid type. Please try a different file."
            )
            reject()
          }
        }
        resolve()
      })
    },
    uploadFile(req) {
      this.$message.info("Your file is uploading...")
      let formData = new FormData()
      formData.append("file", req.file)
      formData.append("collection", this.collectionType.slug)
      return this.axios.post(this.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    addFileToAsset(response, file, fileList) {
      let data = {
        campaign: this.campaign,
        collection: this.collection,
        asset: this.asset.slug,
        data: response.data.data,
      }
      this.addFile(data)
      let index = findIndex(fileList, { uid: file.uid })
      fileList.splice(index, 1)
      this.$message.success("Your file was sucessfully added.")
    },
    hasExtension(fileName, exts) {
      return new RegExp(
        "(" + exts.join("|").replace(/\./g, "\\.") + ")$",
        "i"
      ).test(fileName)
    },
    initReOrder() {
      this.table.initReOrder()
    },
    cancelReOrder() {
      this.table.cancelReOrder()
    },
    saveReOrder() {
      this.table.saveReOrder()
    },
    openLinkModal() {
      this.$modal.show(
        LinkCreateForm,
        {
          asset: this.asset,
          collectionType: this.collectionType,
          campaign: this.campaign,
          collection: this.collection,
        },
        {
          name: "link-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    editAsset() {
      this.$modal.show(
        AssetEditForm,
        {
          existing: this.asset,
          campaign: this.campaign,
          collection: this.collection,
        },
        {
          name: "asset-edit-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    deleteAsset() {
      this.$confirm(
        "Are you sure? <strong>This action cannot be undone</strong> and all associated files " +
          "and links will be deleted.",
        "Delete " + this.asset.name + "?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "error",
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action === "confirm") {
              this.axios
                .post(this.deleteAction, {})
                .then((response) => {
                  let data = {
                    campaign: this.campaign,
                    data: response.data.data,
                    collection: this.collection,
                  }
                  this.removeAsset(data)
                  this.$message.success(response.data.message)
                })
                .catch((resp) => {
                  this.$root.globalRequestError(resp)
                })
            }
          },
        }
      ).catch(() => {})
    },
    publishAsset() {
      this.axios
        .post(this.publishAction, {})
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.collection,
            data: response.data.data,
          }

          this.updateAsset(data)
          this.$message.success(response.data.message)
        })
        .catch((resp) => {
          this.$root.globalRequestError(resp)
        })
    },
    responseParseFunc(response) {
      let headers = response.xhr.getAllResponseHeaders()
      let token = headers.Authorization || headers.authorization
      if (token) {
        token = token.split(/Bearer:?\s?/i)
        this.$auth.token(token)
      }
    },
    unapproveAsset() {
      this.$confirm("Are you sure?", "Unapprove " + this.asset.name + "?", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          if (action === "confirm") {
            this.approvalLoading = true
            this.axios
              .post(this.approvalAction, {})
              .then((response) => {
                let data = {
                  campaign: this.campaign,
                  collection: this.collection,
                  data: response.data.data,
                }

                this.updateAsset(data)
                this.$message.success(response.data.message)
                this.approvalLoading = false
              })
              .catch((resp) => {
                this.$root.globalRequestError(resp)
                this.approvalLoading = false
              })
          }
        },
      }).catch(() => {})
    },
    insertLineBreak() {
      let action =
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset.slug +
        "/create"
      this.axios
        .post(action, {
          collection: this.collection,
          file: null,
        })
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.collection,
            asset: this.asset.slug,
            data: response.data.data,
          }
          this.addFile(data)
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  mounted() {
    this.isMounted = true
  },
}
</script>
