<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span> {{ file.name ? file.name : file.file_name }} comments </span>
      <el-button type="text" @click="$modal.hide('file-comments-' + file.id)">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <comment-list
      :file="file"
      :asset="asset"
      :collection="collection"
      :campaign="campaign"
    ></comment-list>

    <comment-form
      :file="file"
      :asset="asset"
      :collection="collection"
      :campaign="campaign"
    ></comment-form>
  </el-card>
</template>

<script>
import CommentForm from "@/partials/Comments/FileForm"
import CommentList from "@/partials/Comments/FileList"

export default {
  name: "FileComments",
  components: {
    CommentForm,
    CommentList,
  },
  props: {
    file: {
      required: true,
    },
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
