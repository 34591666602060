var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"540px"}},[_c('base-media',{attrs:{"collection":_vm.collection,"campaign":_vm.campaign,"asset":_vm.asset,"file":_vm.data[0]}},[_c('div',{staticClass:"fb-carousel"},[_c('div',{staticClass:"fb-carousel-content"},[_c('div',{staticClass:"fb-user-header"},[_c('a',{staticClass:"fb-logo",attrs:{"href":"#"}},[_c('svg',{staticClass:"fb-logo-image",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1400 1065.8"},domProps:{"innerHTML":_vm._s(_vm.logo)}})]),_c('div',{staticClass:"fb-user-info"},[_c('div',{staticClass:"fb-user-info-wrapper"},[_c('div',{staticClass:"fb-user-name"},[_vm._v("EXAMPLE")]),_c('div',{staticClass:"fb-text"},[_vm._v("Sponsored")])])])]),_c('div',{staticClass:"fb-user-content",domProps:{"textContent":_vm._s(_vm.headline)}}),_c('div',{staticClass:"fb-carousel-slides-wrapper",on:{"mouseover":_vm.setArrowContainerVisible,"mouseout":_vm.setArrowContainerInvisible}},[_c('ul',{staticClass:"fb-carousel-slides",style:({
              'transition-duration': '500ms',
              transform: _vm.getLeftPosition(),
            })},_vm._l((_vm.data),function(slide,index){return (slide.type !== 'blank')?_c('li',{key:index,staticClass:"fb-carousel-slide"},[_c('div',{staticStyle:{"height":"300px"}},[_c('img',{staticStyle:{"width":"300px"},attrs:{"src":slide.src}})]),_c('div',{staticClass:"fb-slide-cta"},[_c('div',{staticClass:"fb-slide-cta-left"},[_c('div',{staticClass:"fb-slide-cta-headline",domProps:{"textContent":_vm._s(
                      slide.text_2
                        ? slide.text_2
                        : 'Example Carousel Headline'
                    )}}),_c('div',{staticClass:"fb-slide-cta-description",domProps:{"textContent":_vm._s(
                      slide.text_3
                        ? slide.text_3
                        : 'Example Carousel Description'
                    )}})]),_c('div',{staticClass:"fb-slide-cta-right"},[_c('button',{domProps:{"textContent":_vm._s(slide.cta ? slide.cta : 'Learn More')}})])])]):_vm._e()}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftArrowVisible),expression:"leftArrowVisible"}],staticClass:"fb-left-arrow",on:{"click":_vm.moveLeft}},[_c('div',{staticClass:"fb-left-arrow-hover",style:({ width: _vm.getLeftArrowContainerWidth() })},[_c('div',{style:({ opacity: _vm.arrowContainerOpacity })})]),_c('div',{staticClass:"fb-left-arrow-button-wrapper"},[_c('div',{staticClass:"fb-left-arrow-button"},[_c('img',{staticStyle:{"display":"block"},attrs:{"src":require("@/assets/images/fb-left.png"),"alt":"","width":"20","height":"20"}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rightArrowVisible),expression:"rightArrowVisible"}],staticClass:"fb-right-arrow",on:{"click":_vm.moveRight}},[_c('div',{staticClass:"fb-right-arrow-hover",style:({ width: _vm.getRightArrowContainerWidth() })},[_c('div',{style:({ opacity: _vm.arrowContainerOpacity })})]),_c('div',{staticClass:"fb-right-arrow-button-wrapper"},[_c('div',{staticClass:"fb-right-arrow-button"},[_c('img',{staticStyle:{"display":"block"},attrs:{"src":require("@/assets/images/fb-right.png"),"alt":"","width":"20","height":"20"}})])])])])]),_c('div',{staticClass:"fb-carousel-footer"},[_vm._v(" UNOFFICIAL REPRODUCTION. FOR DEMONSTRATION PURPOSES ONLY. ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }