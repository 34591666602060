<template>
  <div class="timelines-container">
    <div
      v-for="image in images"
      :key="image.id"
      :class="{
        'timeline-new-line': image.type === 'blank',
        'timeline-container': image.type !== 'blank',
      }"
    >
      <base-media
        :collection="collection"
        :campaign="campaign"
        :asset="asset"
        :file="image"
        v-if="image.type !== 'blank'"
      >
        <timeline-single :data="image"></timeline-single>
      </base-media>
    </div>
  </div>
</template>

<style lang="scss">
.timelines-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .timeline-new-line {
    width: 100%;
  }

  .timeline-wrapper {
    width: 500px;
  }
}
</style>

<script>
import TimelineSingle from "@/partials/Media/TimelineSingle"
import BaseMedia from "./Base"

import ImagesLoaded from "vue-images-loaded"

export default {
  name: "FacebookTimeline",

  directives: {
    ImagesLoaded,
  },
  components: { TimelineSingle, BaseMedia },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      hidden: false,
    }
  },

  computed: {
    images() {
      return this.data
    },
  },
  watch: {},

  beforeDestroy() {
    this.hidden = true
  },

  created() {},
}
</script>
