<template>
  <div class="file-wrapper" :id="'file-' + file.id">
    <slot></slot>
    <div class="file-attributes">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
      <div class="actions">
        <el-tooltip effect="dark" placement="bottom">
          <div slot="content">{{ tooltipMessage }}</div>
          <font-awesome-icon
            icon="check-circle"
            class="success"
            v-if="file.approved"
          ></font-awesome-icon>
          <font-awesome-icon
            icon="check-circle"
            class="light has-cursor-pointer"
            @click="approve"
            :disabled="approvalLoading"
            v-else-if="
              !file.approved && $auth.check(['super', 'approval-client'])
            "
          ></font-awesome-icon>
          <font-awesome-icon
            v-else
            icon="check"
            class="light"
          ></font-awesome-icon>
        </el-tooltip>
        <a @click="openCommentsModal" class="comments-link has-cursor-pointer">
          <font-awesome-icon
            icon="comments"
            :class="{ grey: true, info: file.comments.length > 0 }"
          ></font-awesome-icon>
          <span
            class="comment-count"
            :class="{ info: file.comments.length > 0 }"
            >{{ file.comments.length }}</span
          >
        </a>
      </div>
    </div>

    <modal v-bind="options" :name="'file-comments-' + file.id">
      <file-comment-modal
        :file="file"
        :asset="asset"
        :campaign="campaign"
        :collection="collection"
      ></file-comment-modal>
    </modal>
  </div>
</template>

<script>
import FileCommentModal from "@/partials/Comments/File"
import { mapActions } from "vuex"
import DateTime from "@/mixins/DateTime"

export default {
  name: "BaseFile",
  mixins: [DateTime],
  components: {
    FileCommentModal,
  },
  props: {
    file: {
      required: true,
    },
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      approvalLoading: false,
      options: {
        adaptive: true,
        height: "auto",
        scrollable: true,
        width: 800,
      },
    }
  },
  computed: {
    approvalAction() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset.slug +
        "/approve/" +
        this.file.id
      )
    },
    tooltipMessage() {
      if (this.file.approved) {
        return (
          "Asset approved by " +
          this.file.approved_by.name +
          " on " +
          this.dateTimeFormat(this.file.approved_at) +
          "."
        )
      } else if (
        !this.file.approved &&
        this.$auth.check(["super", "approval-client"])
      ) {
        return "Pending approval. Click to approve."
      } else {
        return "Pending approval."
      }
    },
  },
  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
    }),
    approve() {
      let message = "Are you sure?"
      if (!this.$auth.check("super")) {
        message += " <strong>This action cannot be undone</strong>."
      } else {
        message += " Files can be unapproved by editing them."
      }
      let name = this.file.name ? this.file.name : this.file.file_name
      name = name ? name : ""
      this.$confirm(message, "Approve " + name + "?", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "info",
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          if (action === "confirm") {
            this.approvalLoading = true
            this.axios
              .post(this.approvalAction, {})
              .then((response) => {
                let data = {
                  campaign: this.campaign,
                  collection: this.collection,
                  data: response.data.data,
                }

                this.updateAsset(data)
                this.$message.success(response.data.message)
                this.approvalLoading = false
              })
              .catch((resp) => {
                this.$root.globalRequestError(resp)
                this.approvalLoading = false
              })
          }
        },
      }).catch(() => {})
    },
    openCommentsModal() {
      this.$modal.show("file-comments-" + this.file.id)
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.file-wrapper {
  margin: 0.5rem;
  background: #fafafa;
  border: 1px solid #c8cdd0;

  .file-attributes {
    display: flex;
    padding: 0.25rem;
    border-top: 1px solid #c8cdd0;

    .left {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .right {
      white-space: nowrap;
      margin-left: 0.25rem;
      display: inherit;
    }

    .actions {
      white-space: nowrap;
      margin: 0 0.25rem;
    }
  }
}

.comments-link {
  color: #ddd !important;
  padding-left: 0.25rem;
  display: inline-flex;
  align-items: center;

  .comment-count {
    font-size: 0.75rem;
    margin-left: 0.25rem;
  }

  .comment-count.info {
    color: #409eff;
  }
}
</style>
