<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Edit {{ existing.name }}</span>
      <el-button type="text" @click="$modal.hide('asset-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="asset.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('description')
            ? form.errors.first('description')
            : null
        "
        label="Description"
      >
        <el-input
          v-model="asset.description"
          type="textarea"
          placeholder="Description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @focus="form.errors.clear('description')"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Save
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AssetEditForm",

  mixins: [],

  props: {
    existing: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
  data() {
    return {
      asset: {},
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      collections: "collections/list",
    }),
    action() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/update/" +
        this.existing.slug
      )
    },
  },

  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
    }),
    update() {
      this.loading = true

      let formData = {
        name: this.asset.name,
        description: this.asset.description,
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.collection,
            data: response.data.data,
          }

          this.updateAsset(data)
          this.$modal.hide("asset-edit-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    this.asset = {
      name: this.existing.name,
      description: this.existing.description,
    }
  },
}
</script>
