<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Reorder Assets</span>
      <el-button type="text" @click="$modal.hide('asset-sort-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-alert
      title="Drag'n'drop Assets into your desired order and press Save to continue."
      type="info"
      style="margin-bottom: 0.5rem"
    ></el-alert>
    <div class="drag-container">
      <vue-draggable v-model="assets">
        <el-row
          v-for="(asset, key) in assets"
          :key="asset.slug"
          type="flex"
          class="asset-row"
        >
          <el-col style="flex: 0; min-width: 50px; font-weight: bold">
            {{ key + 1 }}
          </el-col>
          <el-col style="flex: 1">
            {{ asset.name }}
          </el-col>
        </el-row>
      </vue-draggable>
    </div>

    <el-button
      @click="submit"
      type="primary"
      plain
      medium
      :style="{ width: '100%' }"
      v-loading="loading"
    >
      Save
    </el-button>
  </el-card>
</template>

<style lang="scss">
.drag-container {
  box-shadow: 0 0 0 1px #c8cdd0;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  .asset-row {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #fefefe;
    cursor: move;
  }

  .asset-row:nth-child(2n) {
    background-color: #fafafa;
  }
}

.sortable-ghost {
  background-color: rgba(64, 158, 255, 0.3) !important;
}
</style>

<script>
import { mapActions } from "vuex"
import VueDraggable from "vuedraggable"

export default {
  name: "AssetSortForm",

  components: {
    VueDraggable,
  },

  props: {
    data: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      assets: [],
      loading: false,
    }
  },
  computed: {
    action() {
      return (
        "assets/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/order/"
      )
    },
  },

  methods: {
    ...mapActions({
      updateCampaign: "campaigns/update",
    }),
    submit() {
      let order = this.$_.map(this.assets, "id")
      this.loading = true

      this.axios
        .post(this.action, { order: order })
        .then((response) => {
          this.updateCampaign(response.data.data)
          this.$message.success(response.data.message)
          this.loading = false
          this.$modal.hide("asset-sort-form")
        })
        .catch((resp) => {
          this.loading = false
          this.$root.globalRequestError(resp)
        })
    },
  },

  created() {
    this.assets = this.$_.cloneDeep(this.data)
  },
}
</script>
