<template>
  <div style="max-width: 540px">
    <base-media
      :collection="collection"
      :campaign="campaign"
      :asset="asset"
      :file="data[0]"
    >
      <div class="fb-carousel">
        <div class="fb-carousel-content">
          <div class="fb-user-header">
            <a class="fb-logo" href="#">
              <svg
                class="fb-logo-image"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1400 1065.8"
                v-html="logo"
              ></svg>
            </a>
            <div class="fb-user-info">
              <div class="fb-user-info-wrapper">
                <div class="fb-user-name">EXAMPLE</div>
                <div class="fb-text">Sponsored</div>
              </div>
            </div>
          </div>
          <div class="fb-user-content" v-text="headline"></div>
          <div
            class="fb-carousel-slides-wrapper"
            @mouseover="setArrowContainerVisible"
            @mouseout="setArrowContainerInvisible"
          >
            <ul
              class="fb-carousel-slides"
              :style="{
                'transition-duration': '500ms',
                transform: getLeftPosition(),
              }"
            >
              <!-- eslint-disable -->
              <li
                class="fb-carousel-slide"
                v-for="(slide, index) in data"
                v-if="slide.type !== 'blank'"
                :key="index"
              >
                <div style="height: 300px">
                  <img :src="slide.src" style="width: 300px" />
                </div>
                <div class="fb-slide-cta">
                  <div class="fb-slide-cta-left">
                    <div
                      class="fb-slide-cta-headline"
                      v-text="
                        slide.text_2
                          ? slide.text_2
                          : 'Example Carousel Headline'
                      "
                    ></div>
                    <div
                      class="fb-slide-cta-description"
                      v-text="
                        slide.text_3
                          ? slide.text_3
                          : 'Example Carousel Description'
                      "
                    ></div>
                  </div>
                  <div class="fb-slide-cta-right">
                    <button
                      v-text="slide.cta ? slide.cta : 'Learn More'"
                    ></button>
                  </div>
                </div>
              </li>
              <!-- eslint-enable -->
            </ul>
            <div
              class="fb-left-arrow"
              v-show="leftArrowVisible"
              @click="moveLeft"
            >
              <div
                class="fb-left-arrow-hover"
                :style="{ width: getLeftArrowContainerWidth() }"
              >
                <div :style="{ opacity: arrowContainerOpacity }"></div>
              </div>
              <div class="fb-left-arrow-button-wrapper">
                <div class="fb-left-arrow-button">
                  <img
                    style="display: block"
                    src="@/assets/images/fb-left.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
            <div
              class="fb-right-arrow"
              v-show="rightArrowVisible"
              @click="moveRight"
            >
              <div
                class="fb-right-arrow-hover"
                :style="{ width: getRightArrowContainerWidth() }"
              >
                <div :style="{ opacity: arrowContainerOpacity }"></div>
              </div>
              <div class="fb-right-arrow-button-wrapper">
                <div class="fb-right-arrow-button">
                  <img
                    style="display: block"
                    src="@/assets/images/fb-right.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fb-carousel-footer">
          UNOFFICIAL REPRODUCTION. FOR DEMONSTRATION PURPOSES ONLY.
        </div>
      </div>
    </base-media>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex"
import svg from "@/theme/svg"
import BaseMedia from "./Base"

export default {
  name: "FacebookCarousel",

  components: {
    BaseMedia,
  },
  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      arrowContainerOpacity: 0,
      currentSlide: 1,
      isHovering: false,
      leftArrowContainerWidth: 94,
      leftArrowVisible: false,
      leftPosition: 12,
      rightArrowContainerWidth: 182,
      rightArrowVisible: true,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      companies: "companies/list",
    }),
    logo() {
      return svg.comet
    },
    company() {
      let index = this.$_.findIndex(this.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.companies[index]
    },
    numSlides() {
      return this.data.filter(function (obj) {
        return obj.type === "image"
      }).length
    },
    headline() {
      if (this.data[0]["text_1"]) return this.data[0]["text_1"]
      return "Example Carousel Text"
    },
  },

  methods: {
    moveLeft() {
      this.transition()
      if (this.currentSlide !== 1) {
        if (this.numSlides === 2) {
          this.leftPosition = this.leftPosition + 130
        } else if (
          this.currentSlide - 1 !== 1 &&
          this.currentSlide !== this.numSlides
        ) {
          this.leftPosition = this.leftPosition + 306
        } else {
          this.leftPosition = this.leftPosition + 218
        }
        this.currentSlide--
      }
      this.updateArrowVisibility()
    },

    moveRight() {
      this.transition()
      if (this.currentSlide !== this.numSlides) {
        if (this.numSlides === 2) {
          this.leftPosition = this.leftPosition - 130
        } else if (
          this.currentSlide + 1 !== this.numSlides &&
          this.currentSlide !== 1
        ) {
          this.leftPosition = this.leftPosition - 306
        } else {
          this.leftPosition = this.leftPosition - 218
        }
        this.currentSlide++
      }
      this.updateArrowVisibility()
    },

    updateArrowVisibility() {
      if (this.currentSlide === 1) {
        this.leftArrowVisible = false
        this.rightArrowContainerWidth = 182
      } else {
        this.leftArrowVisible = true
        this.rightArrowContainerWidth = 94
      }

      if (this.currentSlide === this.numSlides) {
        this.rightArrowVisible = false
        this.leftArrowContainerWidth = 182
      } else {
        this.rightArrowVisible = true
        this.leftArrowContainerWidth = 94
      }
    },

    getLeftPosition() {
      return "translate3d(" + this.leftPosition + "px, 0px, 0px)"
    },

    getLeftArrowContainerWidth() {
      return this.leftArrowContainerWidth + "px"
    },

    getRightArrowContainerWidth() {
      return this.rightArrowContainerWidth + "px"
    },

    setArrowContainerVisible() {
      this.isHovering = true
      return (this.arrowContainerOpacity = 0.8)
    },

    setArrowContainerInvisible() {
      this.isHovering = false
      return (this.arrowContainerOpacity = 0)
    },

    transition() {
      this.arrowContainerOpacity = 0
      setTimeout(
        function () {
          if (this.isHovering) {
            this.arrowContainerOpacity = 0.8
          }
        }.bind(this),
        500
      )
    },
  },
}
</script>

<style scoped lang="scss">
.fb-carousel {
  font-family: "Helvetica Neue", system-ui, sans-serif;
  color: #1d2129;
  width: 498px;
  background-color: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  margin: 10px auto;
}

.fb-carousel-content {
  padding: 12px 12px 0;
}

.fb-carousel-footer {
  margin: 0 12px;
  color: #7f7f7f;
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
}

.fb-user-header {
  margin-bottom: 11px;
  height: 40px;
}

.fb-logo {
  margin-right: 8px;
  float: left;
}

.fb-logo-image {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.fb-user-info {
  display: inline-block;
  height: 40px;
  margin-bottom: 11px;
}

.fb-user-info .fb-user-info-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 44px;
}

.fb-user-name {
  color: rgb(54, 88, 153);
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 2px;
}

.fb-text {
  color: rgb(96, 103, 112);
  font-size: 12px;
  line-height: 16px;
}

.fb-user-content {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.38;
  white-space: normal;

  p {
    color: rgb(29, 33, 41);
    margin-top: 6px;
    font-size: 14px;
    line-height: 20px;
  }
}

.fb-user-content > p {
}

.fb-carousel-slides-wrapper {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  margin-right: -24px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  left: -12px;
  max-height: 385px;
}

.fb-carousel-slides {
  display: flex;
  padding: 1px 0 4px;
  position: relative;
  top: 0;
  margin: 0;
}

.fb-slide-cta {
  height: 54px;
  padding: 6px 8px;
  border: 1px solid;
  border-color: #e9ebee #e9ebee #ced0d4;
  display: flex;

  .fb-slide-cta-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .fb-slide-cta-headline {
      color: #000;
      height: 36px;
      margin-bottom: 1px;
      margin-top: 1px;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      font-weight: bold;
      white-space: normal;
    }

    .fb-slide-cta-description {
      color: #1d2129;
      height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
    }
  }

  .fb-slide-cta-right {
    align-self: center;
    margin-left: 0.25rem;

    button {
      color: rgb(75, 79, 86);
      background-color: #eff1f3;
      border: 1px solid #bec3c9;
      border-radius: 2px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 700;
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
}

.fb-cta-button-wrapper {
  bottom: -4px;
  position: relative;
  z-index: 2;
  margin: 15px 2px 0 2px;
  float: right;
}

.fb-cta-button {
  text-decoration: none;
  line-height: 22px;
  background-color: #f6f7f9;
  border-color: #ced0d4;
  color: #4b4f56;
  transition: 200ms cubic-bezier(0.08, 0.52, 0.52, 1) background-color,
    200ms cubic-bezier(0.08, 0.52, 0.52, 1) box-shadow,
    200ms cubic-bezier(0.08, 0.52, 0.52, 1) transform;
  border: 1px solid;
  border-radius: 2px;
  box-sizing: content-box;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  justify-content: center;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  cursor: pointer;
  height: 20px;
}

.fb-cta-line-1 {
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  font-weight: bold;
  height: 36px;
  -webkit-line-clamp: 2;
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}

.fb-cta-line-2 {
  color: #1d2129;
  height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}

.fb-left-arrow-hover {
  width: 169px;
  left: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 11;
}

.fb-left-arrow-hover > div {
  background-color: #e9ebee;
  bottom: 4px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 1px;
  transition: opacity 0.2s;
  width: 100%;
}

.fb-right-arrow-hover {
  width: 169px;
  right: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 11;
}

.fb-right-arrow-hover > div {
  background-color: #e9ebee;
  bottom: 4px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 1px;
  transition: opacity 0.2s;
  width: 100%;
}

.fb-right-arrow-button-wrapper {
  outline: none;
  right: -2px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 12;
  cursor: pointer;
}

.fb-right-arrow-button {
  border-radius: 2px;
  display: inline-block;
  position: relative;
  border: 1.5px solid #dfe0e4;
  padding: 8px 9px;
  background-color: #fff;
}

.fb-left-arrow-button-wrapper {
  left: -2px;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 12;
  cursor: pointer;
}

.fb-left-arrow-button {
  border-radius: 2px;
  display: inline-block;
  position: relative;
  border: 1.5px solid #dfe0e4;
  padding: 8px 9px;
  background-color: #fff;
}

.fb-carousel-slide {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.05);
  margin: 0 6px 0 0;
  white-space: normal;
  width: 300px;
  display: inline-block;
}
</style>
