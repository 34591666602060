<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span
        >Update
        {{ this.collectionType.slug !== "emails" ? "Link" : "Email" }}</span
      >
      <el-button type="text" @click="$modal.hide('link-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('url') ? form.errors.first('url') : null"
        label="URL"
        required
      >
        <el-input
          v-model="link.url"
          type="text"
          placeholder="URL"
          @focus="form.errors.clear('url')"
          v-input-focus
        >
          <template slot="append" v-if="link.email && !secure">
            <el-tooltip
              class="item"
              effect="dark"
              content="Emails that are insecure cannot be embedded. Please use https."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="warning"
                icon="exclamation"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        :label="link.email ? 'Subject' : 'Name'"
      >
        <el-input
          v-model="link.name"
          type="text"
          :placeholder="link.email ? 'Subject' : 'Name'"
          @focus="form.errors.clear('name')"
        >
          <template slot="append">
            <el-tooltip
              class="item"
              effect="dark"
              content="Optional. Title of the website will be used if left blank."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="info"
                icon="info-circle"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="link.email"
        :error="form.errors.first('from') ? form.errors.first('from') : null"
        label="From"
      >
        <el-input
          v-model="link.from"
          type="text"
          placeholder="From"
          @focus="form.errors.clear('from')"
        >
          <template slot="append">
            <el-tooltip
              class="item"
              effect="dark"
              content="Optional. Current name of company will be used if left blank."
              placement="bottom-end"
            >
              <font-awesome-icon
                class="info"
                icon="info-circle"
                fixed-width
              ></font-awesome-icon>
            </el-tooltip>
          </template>
        </el-input>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Save
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "FileEditForm",

  mixins: [],

  props: {
    existing: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
    asset: {
      required: true,
    },
    collectionType: {
      required: true,
    },
  },
  data() {
    return {
      link: {
        url: "",
        type: "links",
        name: "",
        from: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    action() {
      return (
        "urls/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset +
        "/update/" +
        this.existing.id
      )
    },
    secure() {
      if (this.link.url.length < 5) {
        return true
      }
      return this.link.url.substring(0, 5) === "https"
    },
  },

  methods: {
    ...mapActions({
      updateFile: "campaigns/updateFile",
    }),
    update() {
      this.loading = true

      let formData = {
        url: this.link.url,
        type: this.link.type,
        name: this.link.name,
        from: this.link.from,
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          let data = {
            campaign: this.campaign,
            asset: this.asset,
            collection: this.collection,
            data: response.data.data,
          }

          this.updateFile(data)
          this.$modal.hide("link-edit-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    if (this.collectionType.slug === "emails") {
      this.link.type = "email"
    }
    this.link = this.$_.cloneDeep(this.existing)
  },
}
</script>
