<template>
  <div
    class="campaign-container"
    v-if="
      (hasFiles && hasPublishedAssets) ||
      $auth.check(['super', 'admin', 'employee'])
    "
  >
    <el-row class="campaign-header">
      <el-col style="flex: 1">
        <p class="campaign-name">{{ campaign.name }}</p>
        <p class="campaign-dates">
          {{ dateTimeFormatString(campaign.start, "MMM Do", "YYYY-MM-DD") }} to
          {{ dateTimeFormatString(campaign.end, "MMM Do", "YYYY-MM-DD") }}
          <a
            v-if="campaign.description && campaign.description !== ''"
            class="has-cursor-pointer"
            @click="descActive = !descActive"
          >
            {{ descActive ? "Less..." : "More..." }}
          </a>
        </p>
        <slide-up-down
          :active="descActive"
          :duration="500"
          v-if="campaign.description && campaign.description !== ''"
        >
          <div class="campaign-description">
            <div
              class="campaign-description-container"
              v-html="campaign.description"
            ></div>
          </div>
        </slide-up-down>
      </el-col>
      <el-col
        style="flex: 0; white-space: nowrap"
        class="campaign-controls"
        v-show="editing"
      >
        <el-button
          v-if="$auth.check(['admin', 'super'])"
          type="primary"
          size="small"
          @click="openCreateAssetModal"
          style="margin-bottom: 0.4rem"
        >
          New Asset
        </el-button>
        <el-dropdown
          v-if="$auth.check(['admin', 'super'])"
          trigger="click"
          @command="campaignDropDown"
        >
          <span class="el-dropdown-link">
            <el-button
              type="text"
              size="small"
              style="margin-right: 0.5rem; margin-left: 0.5rem"
            >
              <font-awesome-icon
                icon="ellipsis-v"
                fixed-width
              ></font-awesome-icon>
            </el-button>
          </span>
          <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
            <el-dropdown-item command="edit">
              <font-awesome-icon
                icon="pencil-alt"
                fixed-width
              ></font-awesome-icon>
              Edit
            </el-dropdown-item>
            <el-dropdown-item command="reorder" v-if="campaigns.length > 1">
              <font-awesome-icon icon="sort" fixed-width></font-awesome-icon>
              Reorder
            </el-dropdown-item>
            <el-dropdown-item command="delete" v-if="$auth.check(['super'])">
              <font-awesome-icon icon="trash" fixed-width></font-awesome-icon>
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row class="collection-campaign-container">
      <collection-view
        v-for="collection in collections"
        :key="collection.type"
        :collection="collection"
        :editing="editing"
        :campaign="campaign.slug"
      ></collection-view>
      <div class="no-assets" v-if="collections.length < 1">
        <div class="body">
          <span>This campaign contains no assets.</span>
          <span v-if="editing && $auth.check(['admin', 'super'])">
            Please
            <a class="has-cursor-pointer" @click="openCreateAssetModal"
              >create one</a
            >.
          </span>
        </div>
      </div>
    </el-row>
  </div>
</template>

<style lang="scss">
</style>

<style lang="scss">
.collection-campaign-container .collection-container:first-child {
  border-top-left-radius: 0;
}

.collection-campaign-container .collection-container:last-child {
  margin-bottom: 1rem;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex"
import AssetCreateForm from "@/components/Forms/AssetCreateForm"
import CampaignEditForm from "@/components/Forms/CampaignEditForm"
import CollectionView from "@/partials/Month/Collection"
import CampaignSortForm from "@/components/Forms/CampaignSortForm"
import DateTime from "@/mixins/DateTime"
import SlideUpDown from "vue-slide-up-down"

export default {
  name: "CampaignView",

  mixins: [DateTime],
  components: {
    CollectionView,
    SlideUpDown,
  },

  props: {
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
    editing: {
      required: true,
    },
  },
  data() {
    return {
      descActive: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      campaigns: "campaigns/list",
    }),
    collections() {
      if (!this.collection) {
        return this.campaign.collections
      } else {
        let index = this.$_.findIndex(this.campaign.collections, {
          type: this.collection.slug,
        })
        return [this.campaign.collections[index]]
      }
    },
    deleteAction() {
      return (
        "campaigns/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/delete/" +
        this.campaign.slug
      )
    },
    hasPublishedAssets() {
      if (this.$auth.check(["admin", "super", "employee"])) return true
      if (!this.collections.length) return false
      let truthy = false
      this.$_.forEach(this.collections, (collection) => {
        if (this.$_.some(collection.assets, "published")) {
          truthy = true
        }

        if (truthy) return false
      })

      return truthy
    },
    hasFiles() {
      if (this.$auth.check(["admin", "super", "employee"])) return true
      if (!this.collections.length) return false
      let truthy = false
      this.$_.forEach(this.collections, (collection) => {
        if (this.$_.some(collection.assets, "file_count")) {
          truthy = true
        }

        if (truthy) return false
      })

      return truthy
    },
  },

  watch: {
    editing(val) {
      if (val === true) {
        this.$_.forOwn(window.lgData, function (value) {
          if (value.constructor.name === "Plugin") {
            value.destroy()
            delete window.lgData.key
          }
        })
      }
    },
  },

  methods: {
    ...mapActions({
      removeCampaign: "campaigns/remove",
    }),
    openCreateAssetModal() {
      this.$modal.show(
        AssetCreateForm,
        {
          existing: this.campaign.slug,
        },
        {
          name: "asset-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openEditCampaignModal() {
      this.$modal.show(
        CampaignEditForm,
        {
          existing: this.campaign,
        },
        {
          name: "campaign-edit-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    deleteCampaign() {
      this.$confirm(
        "Are you sure? <strong>This action cannot be undone</strong> and all associated files " +
          "and links will be deleted.",
        "Delete " + this.campaign.name + "?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "error",
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action === "confirm") {
              this.axios
                .post(this.deleteAction, {})
                .then((response) => {
                  this.removeCampaign(response.data.data)
                  this.$message.success(response.data.message)
                })
                .catch((resp) => {
                  this.$root.globalRequestError(resp)
                })
            }
          },
        }
      ).catch(() => {})
    },
    openReorderCampaignsModal() {
      this.$modal.show(
        CampaignSortForm,
        {
          data: this.campaigns,
        },
        {
          name: "campaign-sort-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    campaignDropDown(command) {
      if (command === "edit") this.openEditCampaignModal()
      if (command === "delete") this.deleteCampaign()
      if (command === "reorder") this.openReorderCampaignsModal()
    },
  },
}
</script>
