<template>
  <div class="file-table">
    <el-row v-if="files.length < 1" style="text-align: center">
      <p>There are no files in this asset.</p>
    </el-row>
    <div v-else>
      <vue-draggable :disabled="!dragging" v-model="files">
        <el-row
          v-for="(file, key) in files"
          :key="file.id"
          :class="{ 'can-drag': dragging, 'file-row': true }"
          type="flex"
          style="align-items: center"
        >
          <el-col
            style="min-width: 30px; flex: 0; text-align: center"
            v-if="dragging"
          >
            {{ key + 1 }}
          </el-col>
          <el-col style="min-width: 30px; flex: 0; text-align: center">
            <span v-if="file.mime_type === null">
              <font-awesome-icon
                icon="arrows-alt-h"
                fixed-width
              ></font-awesome-icon>
            </span>
            <span v-else-if="file.mime_type === 'link' && file.email">
              <font-awesome-icon
                icon="envelope"
                fixed-width
              ></font-awesome-icon>
            </span>
            <span v-else-if="file.mime_type === 'link' && !file.email">
              <font-awesome-icon icon="link" fixed-width></font-awesome-icon>
            </span>
            <span v-else>
              <font-awesome-icon
                :icon="fileTypeIcon(file)"
                fixed-width
              ></font-awesome-icon>
            </span>
          </el-col>
          <el-col
            style="
              flex: 1;
              min-width: 200px;
              display: flex;
              align-items: center;
            "
          >
            <div
              v-if="file.mime_type === null"
              style="width: 100%; text-align: center"
            >
              --- LINE BREAK ---
            </div>
            <div v-else-if="file.mime_type !== 'link' && file.name !== null">
              {{ file.name }}
            </div>
            <div v-else-if="file.mime_type !== 'link' && file.name === null">
              <span class="unnamed-file">
                {{ file.file_name }}
              </span>
            </div>
            <div v-else-if="file.mime_type === 'link'">
              <span :class="{ 'unnamed-file': file.name === null }">
                {{ file.name !== null ? file.name : "(Unnamed)" }}
              </span>
              <br />
              <a :href="file.url" class="file-url-link" target="_blank">
                {{ file.url }}
              </a>
            </div>
          </el-col>
          <el-col class="file-properties">
            <span v-if="file.mime_type === 'link' && file.email">
              <font-awesome-icon
                icon="envelope-open"
                fixed-width
              ></font-awesome-icon>
              {{ file.from }}
            </span>
            <span v-if="file.mime_type !== 'link' && file.mime_type !== null">
              <span>{{ file.file_type }}</span>
              <span v-if="file.width">
                {{ file.width }} x {{ file.height }}
              </span>
              <span v-if="file.duration">
                {{ file.duration | formatLength }}
              </span>
              <span>{{ file.size | formatSize }}</span>
            </span>
            <span v-if="file.type === 'video'" style="margin-left: 0.5rem">
              <a :href="file.vimeo_url" target="_blank">
                <font-awesome-icon
                  :icon="['fab', 'vimeo']"
                  fixed-width
                ></font-awesome-icon>
              </a>
            </span>
            <el-dropdown trigger="click" @command="fileDropdown($event, file)">
              <span class="el-dropdown-link">
                <el-button
                  type="text"
                  size="small"
                  style="margin-right: 0.5rem; margin-left: 0.5rem"
                >
                  <font-awesome-icon
                    icon="ellipsis-v"
                    fixed-width
                  ></font-awesome-icon>
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="unapprove"
                  v-if="file.approved && $auth.check('super')"
                  v-loading="approvalLoading"
                >
                  <font-awesome-icon
                    icon="times"
                    fixed-width
                  ></font-awesome-icon>
                  Unapprove
                </el-dropdown-item>
                <el-dropdown-item command="edit" v-if="file.mime_type !== null">
                  <font-awesome-icon
                    icon="pencil-alt"
                    fixed-width
                  ></font-awesome-icon>
                  Edit
                </el-dropdown-item>
                <el-dropdown-item command="delete">
                  <font-awesome-icon
                    icon="trash"
                    fixed-width
                  ></font-awesome-icon>
                  Delete
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </vue-draggable>
    </div>
  </div>
</template>

<style lang="scss">
.file-table {
  font-size: 1rem;

  .file-row .el-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-url-link {
    font-size: 0.8rem;
    color: #909399;
  }

  .file-url-link:hover,
  .file-url-link:active {
    color: #409eff;
  }

  .file-row {
    padding: 0.25rem;
    font-size: 0.9rem;
    background-color: #fefefe;
  }

  .file-row:nth-child(2n) {
    background-color: #fafafa;
  }

  .unnamed-file {
    color: #777;
    font-style: italic;
  }

  .file-properties {
    flex: 0;
    font-size: 0.8rem;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 250px;
  }
}
</style>

<style>
.can-drag {
  cursor: move;
}

.sortable-ghost {
  background-color: rgba(64, 158, 255, 0.3);
}
</style>

<script>
import FileEditForm from "@/components/Forms/FileEditForm"
import LinkEditForm from "@/components/Forms/LinkEditForm"
import FileTypeIcons from "@/theme/fileIcons"

import VueDraggable from "vuedraggable"
import { mapActions } from "vuex"

export default {
  name: "FileTable",

  components: {
    VueDraggable,
  },

  props: {
    data: {
      required: true,
    },
    campaign: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    collectionType: {
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      oldModel: [],
      approvalLoading: false,
      reorderLoading: false,
      files: [],
    }
  },
  computed: {
    reorderAction() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset +
        "/order"
      )
    },
    deleteAction() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset +
        "/delete/"
      )
    },
    approvalAction() {
      return (
        "files/" +
        this.$route.params.company +
        "/" +
        this.$route.params.month +
        "/" +
        this.campaign +
        "/" +
        this.asset +
        "/unapprove/"
      )
    },
  },
  watch: {
    data(newVal) {
      this.files = this.$_.cloneDeep(newVal)
    },
  },

  methods: {
    ...mapActions({
      updateAsset: "campaigns/updateAsset",
      removeFile: "campaigns/removeFile",
    }),
    openEditFile(file) {
      if (file.mime_type !== "link") {
        this.$modal.show(
          FileEditForm,
          {
            existing: file,
            campaign: this.campaign,
            asset: this.asset,
            collection: this.collection,
          },
          {
            name: "file-edit-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      } else {
        this.$modal.show(
          LinkEditForm,
          {
            existing: file,
            campaign: this.campaign,
            asset: this.asset,
            collection: this.collection,
            collectionType: this.collectionType,
          },
          {
            name: "link-edit-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      }
    },

    openDeleteFile(file) {
      let message =
        "Delete " +
        (file.name ? file.name : "the file") +
        "? This action cannot be undone."
      this.$confirm(message, "", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "error",
        callback: (action) => {
          if (action === "confirm") {
            this.axios
              .post(this.deleteAction + file.id, {})
              .then((response) => {
                let data = {
                  campaign: this.campaign,
                  asset: this.asset,
                  collection: this.collection,
                  data: response.data.data,
                }
                this.removeFile(data)
                this.$message.success(response.data.message)
              })
              .catch((resp) => {
                this.$root.globalRequestError(resp)
              })
          }
        },
      }).catch(() => {})
    },

    initReOrder() {
      this.dragging = true
      this.oldModel = this.$_.cloneDeep(this.files)
    },

    saveReOrder() {
      let order = this.$_.map(this.files, "id")
      this.reorderLoading = true

      this.axios
        .post(this.reorderAction, { order })
        .then((response) => {
          let data = {
            campaign: this.campaign,
            collection: this.collection,
            data: response.data.data,
          }

          this.updateAsset(data)
          this.$message.success(response.data.message)
          this.reorderLoading = false
          this.dragging = false
        })
        .catch((resp) => {
          this.reorderLoading = false
          this.$root.globalRequestError(resp)
          this.cancelReOrder()
        })
    },

    cancelReOrder() {
      this.files = this.$_.cloneDeep(this.oldModel)
      this.dragging = false
    },

    fileTypeIcon(file) {
      let key = this.$_.findKey(FileTypeIcons, function (o) {
        return o.indexOf(file.file_type) !== -1
      })

      return key ? key : "file"
    },

    fileDropdown(command, file) {
      if (command === "edit") this.openEditFile(file)
      if (command === "delete") this.openDeleteFile(file)
      if (command === "unapprove") this.unapproveFile(file)
    },

    unapproveFile(file) {
      this.$confirm(
        "Are you sure?",
        "Unapprove " + (file.name ? file.name : file.file_name) + "?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action === "confirm") {
              this.approvalLoading = true
              this.axios
                .post(this.approvalAction + file.id, {})
                .then((response) => {
                  let data = {
                    campaign: this.campaign,
                    collection: this.collection,
                    data: response.data.data,
                  }

                  this.updateAsset(data)
                  this.$message.success(response.data.message)
                  this.approvalLoading = false
                })
                .catch((resp) => {
                  this.$root.globalRequestError(resp)
                  this.approvalLoading = false
                })
            }
          },
        }
      ).catch(() => {})
    },
  },

  created() {
    this.files = this.$_.cloneDeep(this.data)
  },
}
</script>
