<template>
  <el-card class="box-card document-preview">
    <div slot="header" class="clearfix modal-heading">
      <span>{{ file.file_name }}</span>
      <el-button type="text" @click="$modal.hide('document-preview')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <iframe
      width="100%"
      height="100%"
      :src="'https://docs.google.com/gview?embedded=true&url=' + file.src"
    ></iframe>
  </el-card>
</template>

<script>
export default {
  name: "FilePreview",
  props: {
    file: {
      required: true,
    },
  },
}
</script>

<style lang="scss">
.document-preview.el-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
  }
}
</style>
