<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span> {{ asset.name }} comments </span>
      <el-button
        type="text"
        @click="$modal.hide('asset-comments-' + campaign + '-' + asset.slug)"
      >
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <comment-list
      :asset="asset"
      :campaign="campaign"
      :collection="collection"
    ></comment-list>

    <p style="margin-top: 0.5rem; margin-bottom: 1rem; text-align: center">
      The ability to add comments to assets has been removed. Instead, please
      comment on individual files moving forward.
    </p>
  </el-card>
</template>

<style>
</style>

<script>
import DateTime from "@/mixins/DateTime"
import CommentList from "@/partials/Comments/List"

export default {
  name: "AssetComments",

  mixins: [DateTime],
  components: {
    CommentList,
  },

  props: {
    asset: {
      required: true,
    },
    campaign: {
      required: true,
    },
    collection: {
      required: true,
    },
  },
}
</script>
