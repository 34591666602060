<template>
  <div>
    <el-row style="margin: 1px">
      <el-col
        v-for="file in data"
        :key="file.id"
        :lg="file.type === 'blank' ? 24 : 6"
        :md="file.type === 'blank' ? 24 : 8"
        :sm="file.type === 'blank' ? 24 : 12"
      >
        <base-media
          :collection="collection"
          :campaign="campaign"
          :asset="asset"
          :file="file"
          v-if="file.type !== 'blank'"
        >
          <div class="file-container">
            <font-awesome-icon
              :icon="fileTypeIcon(file)"
              fixed-width
              size="3x"
              style="margin-bottom: 0.5rem"
            ></font-awesome-icon>
            {{ file.file_name }}
          </div>
          <template slot="left">
            {{ file.size | formatSize }}
          </template>
          <template slot="right">
            <a
              v-if="previewable.indexOf(file.file_type) !== -1"
              @click="documentPreview(file)"
              style="cursor: pointer; margin-right: 0.5rem"
            >
              <font-awesome-icon icon="eye" fixed-width></font-awesome-icon>
            </a>
            <a :href="file.src" target="_blank">
              <font-awesome-icon
                icon="download"
                fixed-width
              ></font-awesome-icon>
            </a>
          </template>
        </base-media>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss">
.file-container {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  text-align: center;

  .fa-file-excel {
    color: #1e683e;
  }

  .fa-file-word {
    color: #214c90;
  }

  .fa-file-powerpoint {
    color: #cd3b18;
  }

  .fa-file-pdf {
    color: #d5231a;
  }

  .fa-file-audio {
    color: #bc56f7;
  }

  .fa-file-video {
    color: #f08c0f;
  }

  .fa-file-image {
    color: #6bd4ff;
  }

  .fa-file-archive {
    color: #f8b634;
  }

  .fa-file-alt {
    color: #24acb8;
  }

  .fa-file {
    color: #a2a2a2;
  }
}

.generic-table {
  font-size: 1rem;

  .file-row .el-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-row {
    padding: 0.25rem;
    font-size: 1rem;
    background-color: #fefefe;
  }

  .file-row:nth-child(2n) {
    background-color: #fafafa;
  }

  .file-properties {
    min-width: 250px;
    flex: 0;
    font-size: 0.8rem;
  }
}
</style>

<script>
import FileTypeIcons from "@/theme/fileIcons"
import FilePreview from "./FilePreview"
import BaseMedia from "./Base"

export default {
  name: "MediaFile",

  components: {
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },

  data() {
    return {
      previewable: ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"],
    }
  },

  methods: {
    documentPreview(file) {
      this.$modal.show(
        FilePreview,
        {
          file: file,
        },
        {
          name: "document-preview",
          adaptive: true,
          height: "90%",
          width: "90%",
        }
      )
    },
    fileTypeIcon(file) {
      let key = this.$_.findKey(FileTypeIcons, function (o) {
        return o.indexOf(file.file_type) !== -1
      })

      return key ? key : "file"
    },
  },
}
</script>
