<template>
  <div class="animated-banners-container">
    <div
      v-for="image in images"
      :key="image.id"
      :class="{ 'animated-new-line': image.type === 'blank' }"
      :style="{ 'max-height': image.height }"
    >
      <base-media
        :collection="collection"
        :campaign="campaign"
        :asset="asset"
        :file="image"
        v-if="image.type !== 'blank'"
        :style="{ width: image.width + 'px' }"
      >
        <animated-banner-single
          :data="image"
          :ref="'item-' + image.id"
        ></animated-banner-single>
        <template slot="left">
          {{ image.name ? image.name : image.file_name }}
        </template>
        <template slot="right">
          {{ image.width }} x {{ image.height }}
          <a
            class="has-cursor-pointer"
            style="margin-left: 0.25rem"
            @click="reload(image.id)"
          >
            <font-awesome-icon icon="sync" full-width></font-awesome-icon>
          </a>
        </template>
      </base-media>
    </div>
  </div>
</template>

<style lang="scss">
.animated-banners-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .animated-container {
    height: 100%;
  }

  .animated-new-line {
    width: 100%;
  }
}
</style>

<script>
import AnimatedBannerSingle from "@/partials/Media/AnimatedBannerSingle"
import BaseMedia from "./Base.vue"

export default {
  name: "MediaAnimatedBanner",

  components: {
    AnimatedBannerSingle,
    BaseMedia,
  },

  props: {
    data: {
      required: true,
    },
    asset: {
      required: true,
    },
    collection: {
      required: true,
    },
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      hidden: false,
    }
  },
  computed: {
    images() {
      return this.data
    },
  },

  methods: {
    getItemClass(item) {
      let cls = "grid-item"

      if (item.width > 1000) {
        cls = cls + " grid-item--width10"
      } else if (item.width > 900) {
        cls = cls + " grid-item--width9"
      } else if (item.width > 700) {
        cls = cls + " grid-item--width8"
      } else if (item.width > 600) {
        cls = cls + " grid-item--width7"
      } else if (item.width > 500) {
        cls = cls + " grid-item--width6"
      } else if (item.width > 400) {
        cls = cls + " grid-item--width5"
      } else if (item.width > 300) {
        cls = cls + " grid-item--width4"
      } else if (item.width > 200) {
        cls = cls + " grid-item--width3"
      } else if (item.width > 100) {
        cls = cls + " grid-item--width2"
      }

      return cls
    },
    getItemHeight(item) {
      return item.height + 38 + "px"
    },
    reload(id) {
      this.$refs["item-" + id][0].reloadIframe()
    },
  },

  beforeDestroy() {
    this.hidden = true
  },

  mounted() {},
}
</script>
