export default {
  'file-audio': ['mp4s', 'mid', 'mp4a', 'mpga', 'oga', 'aac', 'flac', 'wma', 'wav'],
  'file-excel': ['xls', 'xlam', 'xlsb', 'xlsm', 'xltm', 'xlsx', 'xltx', 'csv', 'tsv'],
  'file-pdf': ['pdf'],
  'file-powerpoint': ['ppt', 'ppam', 'pptm', 'sldm', 'ppsm', 'potm', 'pptx', 'sldx', 'ppsx', 'potx'],
  'file-word': ['doc', 'docm', 'dotm', 'docx', 'dotx'],
  'file-video': ['swf', '3gp', '3g2', 'h261', 'h263', 'h264', 'mp4', 'mpeg', 'flv', 'm4v', 'mkv'],
  'file-alt': ['wps', 'odt', 'txt', 'rtx', 'rtf'],
  'file-image': ['bmp', 'gif', 'jpeg', 'png', 'svg', 'tiff', 'psd'],
  'file-archive': ['7z', 'ace', 'dmg', 'bz', 'bz2', 'rar', 'tar', 'zip']
}
