<template>
  <div class="fb-timeline">
    <div class="fb-timeline-content">
      <div class="fb-timeline-header">
        <div class="fb-timeline-header-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1400 1065.8"
            v-html="logo"
          ></svg>
        </div>
        <div class="fb-timeline-header-headings">
          <div class="fb-timeline-header-main">EXAMPLE</div>
          <div class="fb-timeline-header-sub">Sponsored</div>
        </div>
      </div>

      <div class="fb-timeline-top-text">
        <p v-text="data.text_1 ? data.text_1 : 'Example Timeline Text'"></p>
      </div>

      <div class="fb-timeline-image-container">
        <img :src="data.src" />
      </div>

      <div class="fb-timeline-bottom-text">
        <div class="copy">
          <p
            class="heading"
            v-text="data.text_2 ? data.text_2 : 'Example Timeline Heading'"
          ></p>
          <p
            class="description"
            v-text="data.text_3 ? data.text_3 : 'Example Timeline Description'"
          ></p>
        </div>
        <div class="cta">
          <button>{{ data.cta ? data.cta : "Learn More" }}</button>
        </div>
      </div>

      <div class="fb-timeline-footer">
        UNOFFICIAL REPRODUCTION. FOR DEMONSTRATION PURPOSES ONLY.
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fb-timeline {
  width: 502px;
  background-color: #fff;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  margin: 10px;
  color: rgb(29, 33, 41);
  font-family: "Helvetica Neue", system-ui, sans-serif;

  .fb-timeline-content {
    padding: 12px 12px 0;

    .fb-timeline-header {
      display: flex;

      .fb-timeline-header-logo {
        width: 42px;
        height: 42px;
        padding-bottom: 3px;
        margin-right: 8px;

        svg {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      .fb-timeline-header-headings {
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
        /*justify-content: center;*/

        .fb-timeline-header-main {
          color: rgb(54, 88, 153);
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
          margin-bottom: 2px;
        }

        .fb-timeline-header-sub {
          color: rgb(96, 103, 112);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .fb-timeline-top-text {
      color: rgb(29, 33, 41);
      font-size: 16px;
      line-height: 20px;
      margin-top: 6px;

      p {
        margin: 0 0 0.25rem;
      }
    }

    .fb-timeline-image-container {
      margin-left: -12px;
      margin-right: -12px;
      border-top: 1px solid #e8e8e8;

      img {
        width: 100%;
        height: auto;
      }
    }

    .fb-timeline-bottom-text {
      margin-left: -12px;
      margin-right: -12px;
      padding: 10px 12px;
      background: #f2f3f5;
      display: flex;

      .copy {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;

        .heading {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin: 5px 0 0;
          overflow: hidden;
          max-height: 110px;
          word-wrap: break-word;
          text-overflow: ellipsis;
          white-space: normal;
          color: #1d2129;
        }

        .description {
          color: #606770;
          font-size: 14px;
          line-height: 20px;
          max-height: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }

      .cta {
        align-self: center;
        padding-left: 18px;

        button {
          color: rgb(75, 79, 86);
          background-color: #eff1f3;
          border: 1px solid #bec3c9;
          border-radius: 2px;
          font-size: 12px;
          line-height: 26px;
          font-weight: 700;
          padding-left: 10px;
          padding-right: 10px;
          white-space: nowrap;
        }
      }
    }

    .fb-timeline-footer {
      color: rgb(144, 148, 156);
      font-weight: 600;
      text-align: center;
      margin: 5px 0;
      font-size: 12px;
      line-height: 18px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

<script>
import svg from "@/theme/svg"

export default {
  name: "FacebookTimelineSingle",

  mixins: [],
  components: {},

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      logo: svg.comet,
    }
  },
  computed: {},
  watch: {},

  methods: {},

  created() {},
  mounted() {},
}
</script>
