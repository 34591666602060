<template>
  <div class="email-embed">
    <div class="header">
      {{ email.name }}
      <a
        href="javascript:void(0)"
        style="float: right; padding: 1px 0"
        @click="$modal.hide('url-embed')"
      >
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </a>
      <a :href="email.url" target="_blank" style="float: right; padding: 1px 0">
        <font-awesome-icon
          icon="external-link-square-alt"
          fixed-width
        ></font-awesome-icon>
      </a>
    </div>
    <iframe frameborder="0" class="email-iframe" :src="email.url"></iframe>
  </div>
</template>

<style lang="scss">
.email-embed {
  .header {
    min-height: 18px;
    font-size: 1.25rem;
    background-color: #fafafa;
    border-bottom: 1px solid #c8cdd0;
    padding: 0.25rem 0.5rem;
  }

  iframe.email-iframe {
    height: calc(95vh - 20px);
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "UrlEmbedMedia",

  props: {
    email: {
      required: true,
    },
  },
}
</script>
